// -----------------------------------------------------------------------------
// This file contains all styles related to the date box component
// -----------------------------------------------------------------------------

.date-box {
  background-color: $african-violet;
  color: $white;
  display: inline-block;
  font-family: $font-brand-bold;
  line-height: 1;
  padding: $space-two-thirds $space-two-thirds $space-half;
  text-align: center;
  text-transform: uppercase;
}

.date-box__day,
.date-box__month {
  display: block;
  line-height: 1;
}

.date-box__day {
  @include type-setting(4);

  line-height: 1;
}
