// -----------------------------------------------------------------------------
// This file contains all styles related to the intro component.
// -----------------------------------------------------------------------------

.intro {
  @extend %module;

  background-color: $african-violet;
  color: $white;
  margin-bottom: $space-and-half;
  margin-left: -$space-five-sixths;
  margin-right: -$space-five-sixths;
  margin-top: -$space;
  padding: $space-three-quarters $space-five-sixths $space-and-quarter;
  position: relative;
  transition: margin 0.3s;

  @include media('≥l') {
    margin-left: -$space-and-two-thirds;
    margin-right: -$space-and-two-thirds;
    padding: $space-and-quarter $space-and-two-thirds $space-and-half;
  }


  // Intro has heading
  &.has-heading {
    padding-bottom: $space;
    padding-top: $space-half;

    @include media('≥l') {
      padding-bottom: $space-and-third;
      padding-top: $space-three-quarters;
    }
  }


  // Banner on page
  .has-banner & {
    @include media('≥m') {
      margin-top: -($space-treble + $space-half);
      z-index: 10;

      &.banner-video-active {
        @include media('≥m') {
          margin-top: 0;
        }
      }
    }

    @include media('≥wide') {
      margin-top: -($space-quadruple + $space-sixth);

      &.banner-video-active {
        @include media('≥m') {
          margin-top: 0;
        }
      }
    }
  }


  // Sidebar on page
  .has-sidebar & {
    @include media('<m') {
      margin-top: -$space-and-half;
    }
  }
}





//
// Heading
//
.intro__heading {
  @extend %page-heading;

  margin-bottom: $space-quarter;
}





//
// Text
//
.intro__text {
  @include type-setting(2);

  font-family: $font-brand-light;
  opacity: 0.85;

  a {
    border-bottom: 1px dotted $white;
    color: $white;

    &:hover,
    &:focus {
      border-bottom-style: solid;
    }
  }
}





//
// Content type container styles
//
.content-type--intro {
  .page-layout-default & {
    @include media('≥m') {
      padding-right: $gutter;
    }
  }

  .page-layout-wide & {
    @extend %container;
  }
}
