// -----------------------------------------------------------------------------
// This file contains all styles related to the content type component
// -----------------------------------------------------------------------------

//
// Inline content type
//
.content-type--inline {
  display: inline-block;
  vertical-align: top;
}





//
// Alignment
//
.content-type--center {
  text-align: center;
}

.content-type--right {
  text-align: right;
}





//
// Add right gutter to content-types in default page layout
//
.page-layout-default {
  .main-content {
    .content-type--toggle,
    .content-type--callout,
    .content-type--lead-paragraph,
    .content-type--main,
    .content-type--embed,
    .content-type--image,
    .content-type--alert,
    .content-type--heading,
    .content-type--step,
    .content-type--tabs,
    .content-type--section-iterator,
    .filters,
    .news-article,
    .event {
      @include media('≥m') {
        padding-right: $gutter;
      }
    }
  }
}





//
// 'Main content' content type spacing
//
.content-type--main {
  .main-content & {
    margin-bottom: $space-half;
  }

  .content-type--button + & {
    margin-top: $space-and-half;
  }

  .content-type--page-heading + & > .content-type__container > .subheading {
    margin-top: 0;
  }
}





//
// Heading content type spacing
//
.content-type--heading {
  .content-type--page-heading + & {
    .heading {
      margin-top: -$space-third;
    }
  }
}





//
// Subheading content type spacing
//
.content-type--subheading {
  .content-type--page-heading + & > .content-type__container > .subheading {
    margin-top: 0;
  }
}
