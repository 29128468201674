// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

//
// Clear inner floats
// @include clearfix;
//
@mixin clearfix() {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}





//
// Type setting (font-size and line height from scale)
// @include type-setting(2);
//
@mixin type-setting($level) {
  @include ms-respond(font-size, map-get(map-get($type-settings, $level), type-scale));

  line-height: map-get(map-get($type-settings, $level), line-height);
}





//
// Type scale (font size from scale)
// @include type-scale(2);
//
@mixin type-scale($level) {
  @include ms-respond(font-size, map-get(map-get($type-settings, $level), type-scale));
}





//
// Column
//
// Default behaviour presumes a 12 column grid:
// `@include col(3);` outputs to `width: 25%;` ((3 / 12) * 100)
//
// Override 12 column grid by entering second value:
// `@include col(2, 10);` outputs to `width: 20%;` ((2 / 10) * 100)
//
@mixin col($column, $columns: '') {
  @if $columns != '' {
    width: math.percentage(math.div($column, $columns));
  } @else {
    width: math.percentage(math.div($column, 12));
  }
}
