// -----------------------------------------------------------------------------
// This file contains all styles related to the contact component.
// -----------------------------------------------------------------------------

.contact {
  .list--contact {
    margin-top: $space-half;
  }

  .main-content & {
    margin-bottom: $space;
  }

  .twitter-follow-button {
    margin-top: $space-half;
  }
}





//
// Contact text
//
.contact__text {
  margin-bottom: 0;
  margin-top: $space-three-quarters;
}





//
// Contact name
//
.contact__name {
  @include type-setting(3);

  display: block;
  font-family: $font-brand;
  font-weight: $font-weight-normal;

  .sidebar & {
    @include type-setting(2);
  }
}





//
// Heading
//
.contact__heading {
  @include type-setting(4);

  margin-bottom: 0;
}





//
// Subheading
//
.contact__subheading {
  @include type-setting(3);

  margin-bottom: 0;
}





//
// Sub-subheading
//
.contact__sub-subheading {
  @include type-setting(2);

  margin-bottom: 0;
}





//
// Display in grid
//
.content-type--contact.content-type--inline {
  display: block;

  @include media('≥s') {
    display: inline-block;
    padding-right: $gutter;
    width: percentage(math.div(6, 12));
  }


  // In main content area
  .main-content & .contact {
    border: 1px solid $fountain-grey;
    margin-bottom: $space;
    padding: $space-three-quarters $space $space;

    @include media('≥s') {
      margin-bottom: $gutter;
    }
  }


  // In wide page layouts
  .page-layout-wide &,
  .page-layout-wide-boxed & {
    @include media('≥s') {
      padding-left: $gutter-half;
      padding-right: $gutter-half;
    }
  }
}
