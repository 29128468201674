// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------

// Resets
.form__fieldset {
  border: 0;
  padding: 0;
}

.form__components {
  position: relative;
}

.form__field {
  appearance: none;
  border-radius: 0;
}





// Default
.form--default {
  .form__components {
    margin-bottom: $space;
  }



  // Label
  .form__label {
    @include type-setting(1);

    display: block;
    font-family: $font-brand-medium;
    margin-bottom: $space-half;
  }



  // Text field
  .form__field {
    @include type-setting(0);

    border: 1px solid $fountain-grey;
    color: $asphalt;
    padding: $space-half;
    transition: border-color 0.3s;

    &:hover,
    &:focus {
      border-color: $asphalt;
    }
  }
}





// Search
.form--search {
  .form__field {
    background: transparent;
    border: 0;
    color: $white;
    font-family: $font-brand;
    font-weight: $font-weight-normal;
    outline: 0;
    padding: 0;
    width: 100%;

    // scss-lint:disable VendorPrefix, PseudoElement
    &::-webkit-input-placeholder {
      color: $white;
    }

    &:-moz-placeholder {
      /* FF 4-18 */
      color: $white;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* FF 19+ */
      color: $white;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $white;
    }
    // scss-lint:enable VendorPrefix, PseudoElement

    &:focus {
      // scss-lint:disable VendorPrefix, PseudoElement
      &::-webkit-input-placeholder {
        opacity: 0;
        transition: opacity 0.5s 0.5s;
      }

      &:-moz-placeholder {
        /* FF 4-18 */
        opacity: 0;
        transition: opacity 0.5s 0.5s;
      }

      &::-moz-placeholder {
        /* FF 19+ */
        opacity: 0;
        transition: opacity 0.5s 0.5s;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        opacity: 0;
        transition: opacity 0.5s 0.5s;
      }
      // scss-lint:enable VendorPrefix, PseudoElement
    }
  }

  .button {
    bottom: 0;
    display: block;
    height: 20px;
    position: absolute;
    right: 0;
    width: 20px;
  }
}




// Filter
.form--filter {
  @include clearfix;

  background-color: $light-grey;
  margin-bottom: $space-and-third;
  padding-bottom: $space-quarter;
  padding-top: $space-quarter;

  @include media('≥s') {
    padding: $space $space-half;
  }

  .form__components {
    padding: $space-quarter $space-half;
  }

  .form__field {
    @include type-setting(0);

    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2025%2025%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20fill%3D%22%235d6b74%22%3E%3Cpath%20d%3D%22M10.183%2019.866C4.835%2019.866.5%2015.53.5%2010.183.5%204.836%204.835.5%2010.183.5a9.683%209.683%200%200%201%209.683%209.683%209.683%209.683%200%200%201-9.683%209.683zm0-1a8.683%208.683%200%201%200%200-17.366%208.683%208.683%200%200%200%200%2017.366z%22/%3E%3Cpath%20d%3D%22M16.506%2017.214l7.38%207.38a.5.5%200%200%200%20.708-.708l-7.38-7.38a.5.5%200%200%200-.708.708z%22/%3E%3C/g%3E%3C/svg%3E%0A');
    background-position: $space-half center;
    background-repeat: no-repeat;
    border: 1px solid $light-grey;
    font-family: $font-brand;
    padding: $space-half $space-half $space-half $space-double;
    width: 100%;

    @include media('≥l') {
      @include type-setting(1);

      padding: $space-two-thirds $space-two-thirds $space-two-thirds $space-double;
    }
  }

  .form__select {
    @include type-setting(0);

    appearance: none;
    background-color: $white;
    background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20width%3D%2210%22%20height%3D%225%22%20viewBox%3D%220%200%2010%205%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EShape%3C%2Ftitle%3E%3Cpath%20d%3D%22M0%200h10.03L5.016%205.016z%22%20fill%3D%22%23595E61%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    background-position: right $space-half center;
    background-repeat: no-repeat;
    border: 1px solid $light-grey;
    border-radius: 0;
    color: $text-color-light;
    display: block;
    font-family: $font-brand;
    padding: $space-half;
    position: relative;
    width: 100%;

    @include media('≥l') {
      @include type-setting(1);

      padding: $space-two-thirds $space-and-third $space-two-thirds $space-two-thirds;
    }
  }



  // Buttons
  .form__components--buttons {
    .button {
      background-color: rgba($asphalt, 0.1);
      color: $mulberry;
      margin-right: $space-quarter;
      margin-top: $space-third;

      &:hover,
      &:focus {
        background-color: $mulberry;
        color: $white;
      }

      &.active {
        background-color: $mulberry;
        color: $white;
      }
    }
  }
}
