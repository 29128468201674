// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

//
// Main content containers
// 1. Make the container full-width with a maximum width
// 2. Center it in the viewport
// 3. Leave some space on the edges, especially valuable on small screens
//
.container,
%container {
  @include clearfix;

  margin-left: auto; // 2
  margin-right: auto; // 2
  max-width: $max-width; // 1
  padding-left: $space-five-sixths; // 3
  padding-right: $space-five-sixths; // 3
  width: 100%; // 1

  @include media('≥wide') {
    padding-left: 0;
    padding-right: 0;
  }
}





//
// Hide text while making it readable for screen readers
// 1. Needed in WebKit-based browsers because of an implementation bug;
// See: https://code.google.com/p/chromium/issues/detail?id=457146
//
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}





//
// Hide element while making it readable for screen readers
//
.visually-hidden {
  @extend %visually-hidden;
}





//
// Spacing
//
.space {
  clear: both;
  height: $space;
}

.space--double {
  height: $space-double;
}

.space--treble {
  height: $space-treble;
}

.space--quadruple {
  height: $space-quadruple;
}





//
// Responsive ratios
//
.responsive-ratio {
  height: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  iframe,
  img {
    border: 0;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.responsive-ratio--16by9 {
  padding-bottom: 56.25%;
}

.responsive-ratio--4by3 {
  padding-bottom: 75%;
}

.responsive-ratio--square {
  padding-bottom: 100%;
}





//
// Caption
//
.caption {
  color: $text-color;
  display: block;
  font-family: $font-brand;
  margin-top: $space-half;
  text-align: center;

  @include media('≤xs') {
    margin-bottom: $space-half;
  }

  @include media('≤l') {
    margin-bottom: 0;
  }
}





//
// Display
//
.display-inline {
  display: inline;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-hide {
  display: none;
}

.display-flex {
  display: flex;
}





//
// Background colours
//
.background-color {
  &--african-violet {
    background-color: $african-violet;
  }

  &--african-violet-light {
    background-color: $african-violet-light;
  }

  &--mulberry {
    background-color: $mulberry;
  }

  &--mulberry-light {
    background-color: $mulberry-light;
  }

  &--golden {
    background-color: $gold;
  }

  &--golden-light {
    background-color: $gold-light;
  }

  &--bronze {
    background-color: $bronze;
  }

  &--bronze-dark {
    background-color: $bronze-dark;
  }

  &--petrol-blue {
    background-color: $petrol-blue;
  }

  &--petrol-blue-light {
    background-color: $petrol-blue-light;
  }

  &--mercia-red {
    background-color: $mercia-red;
  }

  &--mercia-red-light {
    background-color: $mercia-red-light;
  }

  &--peacock {
    background-color: $peacock;
  }

  &--peacock-light {
    background-color: $peacock-light;
  }

  &--neptune {
    background-color: $neptune;
  }

  &--neptune-light {
    background-color: $neptune-light;
  }

  &--olympic-green {
    background-color: $olympic-green;
  }

  &--olympic-green-dark {
    background-color: $olympic-green-dark;
  }

  &--cedar {
    background-color: $cedar;
  }

  &--cedar-light {
    background-color: $cedar-light;
  }

  &--asphalt {
    background-color: $asphalt;
  }

  &--silver {
    background-color: $silver;
  }

  &--pebble {
    background-color: $pebble;
  }

  &--dove-grey {
    background-color: $dove-grey;
  }

  &--light-grey {
    background-color: $light-grey;
  }

  &--white {
    background-color: $white;
  }

  &--black {
    background-color: $black;
  }
}





//
// Type setting
//
.type-setting-minus-2 {
  @include type-setting(-2);
}

.type-setting-minus-1 {
  @include type-setting(-1);
}

.type-setting-0 {
  @include type-setting(0);
}

.type-setting-1 {
  @include type-setting(1);
}

.type-setting-2 {
  @include type-setting(2);
}

.type-setting-3 {
  @include type-setting(3);
}

.type-setting-4 {
  @include type-setting(4);
}

.type-setting-5 {
  @include type-setting(5);
}

.type-setting-6 {
  @include type-setting(6);
}

.type-setting-7 {
  @include type-setting(7);
}

.type-setting-8 {
  @include type-setting(8);
}

.type-setting-9 {
  @include type-setting(9);
}

.type-setting-10 {
  @include type-setting(10);
}

.type-setting-11 {
  @include type-setting(11);
}

.type-setting-12 {
  @include type-setting(12);
}

.type-setting-13 {
  @include type-setting(13);
}

.type-setting-14 {
  @include type-setting(14);
}

.type-setting-15 {
  @include type-setting(15);
}

.type-setting-16 {
  @include type-setting(16);
}

.type-setting-17 {
  @include type-setting(17);
}

.type-setting-18 {
  @include type-setting(18);
}

.type-setting-19 {
  @include type-setting(19);
}

.type-setting-20 {
  @include type-setting(20);
}
