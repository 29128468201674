// -----------------------------------------------------------------------------
// This file contains all styles related to the lead paragraph component.
// -----------------------------------------------------------------------------

.lead-paragraph {
  @extend %lead-paragraph;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}
