// -----------------------------------------------------------------------------
// This file contains styles that are specific to a staff profile page.
// -----------------------------------------------------------------------------


// OLD

/*
.staff-profile {
  @include media('≥m') {
    padding-right: $gutter;
  }


  // Tabs
  + .content-type--tabs {
    margin-top: $space-and-half;

    .tabs {
      margin-top: 0;
    }
  }
}

// Key info (meta and image)
.staff-profile__key-info {
  @include clearfix;
}

// Meta
.staff-profile__meta {
  @include media('≥xs') {
    padding-right: $space;
    width: percentage(math.div(9, 12));
  }

  @include media('≥wide') {
    width: percentage(math.div(8, 12));
  }
}

// Image
.staff-profile__image-wrapper {
  margin-bottom: $space-two-thirds;
  text-align: center;

  @include media('≥xs') {
    float: right;
    margin-bottom: 0;
    text-align: right;
    width: percentage(math.div(3, 12));
  }
}

.staff-profile__image {
  display: inline-block;
  max-width: 100%;
}

// Heading
.staff-profile__heading {
  @extend %page-heading;

  color: $african-violet;
  margin-bottom: 0;
}

// Pronouns
.staff-profile__pronouns {
  @include type-setting(1);

  font-family: $font-brand;
  margin: 0 0 0.5rem;
}

.list__pronouns .small {
  display: block;
  font-family: $font-brand;
  margin-top: -0.3rem;
}

// Subheading (qualifications)
.staff-profile__subheading {
  @include type-setting(2);

  border-bottom: 1px solid $fountain-grey;
  color: $text-color-lightest;
  margin-bottom: $space-two-thirds;
  padding-bottom: $space-three-quarters;
}

// Roles
.staff-profile__roles {
  @include type-setting(4);

  font-family: $font-brand;
  margin-bottom: $space;


  // Reduce font size if there are multiple roles
  .has-job-roles &,
  .has-qualifications & {
    @include type-setting(2);
  }
}

// Contact
.staff-profile__contact {
  .list__item {
    display: inline-block;
  }
}

// Media bookings alert
.staff-profile__alert {
  margin-bottom: 0;
  margin-top: $space-half;

  @include media('≥s') {
    margin-top: $space-and-half;
  }
}

// External inks
.staff-profile__links {
  margin: $space (-$space-five-sixths) 0;
  padding: $space-half $space-five-sixths $space-quarter;
  position: relative;

  @include media('≥xs') {
    display: flex;
    flex-wrap: wrap;
  }

  @include media('≥s') {
    margin-top: $space-and-half;
  }

  @include media('≥m') {
    margin-right: 0;
  }

  @include media('≥wide') {
    @include type-setting(-1);
  }


  // List item
  .list__item {
    display: block;
    margin-bottom: $space-quarter;
  }


  // Link
  .list__link {
    align-items: center;
    display: flex;
  }


  // Icon
  .icon-wrapper {
    background-color: $asphalt;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    height: $space-and-half;
    margin-right: $space-third;
    padding-left: $space-third;
    padding-right: $space-third;
    position: relative;
    text-align: center;
    width: $space-and-half;
  }

  .icon__svg {
    fill: $white;
  }


  // Text
  .list__text {
    padding-right: $space-half;
  }


  // Extend background colour to left edge
  &::before {
    @include media('≥max') {
      content: '';
      height: 100%;
      left: -2000px;
      position: absolute;
      top: 0;
      width: 2000px;
    }
  }
}

// Research links
.staff-profile__links--research {
  background-color: $light-grey;


  // List item
  .list__item {
    @include media('≥l') {
      width: percentage(math.div(6, 12));
    }
  }


  // Icon
  .icon {
    height: 100%;
    width: 65%;
  }


  // Background colour of left edge
  &::before {
    @include media('≥max') {
      background-color: $light-grey;
    }
  }
}

// External links
.staff-profile__links--external {
  background-color: darken($light-grey, 5%);


  // Remove top margin if research links are positioned above
  .staff-profile__links--research + & {
    margin-top: 0;
  }


  // List item
  .list__item {
    @include media('≥xs') {
      padding-right: $space-quarter;
      width: percentage(math.div(6, 12));
    }

    @include media('≥s', '<m') {
      width: percentage(math.div(4, 12));
    }

    @include media('≥l') {
      width: percentage(math.div(4, 12));
    }

    @include media('≥max') {
      width: percentage(math.div(3, 12));
    }
  }

  .list__item--linkedin,
  .list__item--orcid {
    .icon-wrapper {
      background-color: transparent;
      padding-left: $space-sixth;
      padding-right: $space-sixth;
    }
  }

  .list__item--linkedin {
    .icon__svg {
      fill: $asphalt;
    }
  }


  // Icon
  .icon {
    height: 100%;
    width: 100%;
  }


  // Background colour of left edge
  &::before {
    @include media('≥max') {
      background-color: darken($light-grey, 5%);
    }
  }
}

// Overview
.staff-profile__overview {
  margin-top: $space;

  @include media('≥s') {
    margin-top: $space-and-quarter;
  }
}
*/





//
// New for May 2023
//

//
// Lead paragraph
//
.page-staff-profile {
  .lead-paragraph {
    color: $african-violet;
    font-family: $font-brand-medium;
  }

  .main-content {
    @include media('≥l') {
      min-height: 760px;
    }
  }
}





//
// Key info
//
.sp__key-info {
  position: relative;

  @include media('≥xs-tweak') {
    .page-layout-wide-boxed & {
      display: flex;
    }
  }

  @include media('≥s') {
    display: flex;
  }
}





//
// Meta
//
.sp__meta {
  @include media('≥xs-tweak') {
    .page-layout-wide-boxed & {
      padding-right: $space;
      width: percentage(math.div(8.5, 12));
    }
  }

  @include media('≥s') {
    padding-right: $space;
    width: percentage(math.div(7, 12));

    .page-layout-wide-boxed & {
      padding-right: $space-treble;
      width: percentage(math.div(8.5, 12));
    }
  }

  @include media('≥l') {
    width: percentage(math.div(8, 12));

    .page-layout-wide-boxed & {
      width: percentage(math.div(8.5, 12));
    }
  }
}





//
// Heading (h1)
//
.sp__heading {
  @extend %page-heading;

  color: $african-violet;
  font-family: $font-brand-bold;
  line-height: 1;
  margin-bottom: 0;
}




//
// Qualifications
//
.sp__qualifications {
  @include type-setting(4);

  color: $african-violet;
  font-family: $font-brand-medium;
  margin-bottom: 0;
  margin-top: 0;
}





//
// Pronouns
//
.sp__pronouns {
  @include type-setting(1);

  color: $african-violet;
  font-family: $font-brand-medium;
}





//
// Roles
//
.sp__roles {
  @include type-setting(4);

  color: $african-violet;
  font-family: $font-brand-medium;
  list-style: disc;
  //margin-bottom: $space-and-quarter;
  margin-bottom: 0;
  margin-top: $space;
  padding-left: $space-five-sixths;

  @include media('≥s') {
    //border-bottom: 4px solid $light-grey;
    //margin-bottom: $space-and-three-quarters;
    margin-top: $space-and-half;
    //padding-bottom: $space-and-half;
  }


  // Reduce font size if there are multiple roles
  .sp--multiple-roles & {
    @include type-setting(2);
  }
}

.sp__roles-item {
  &::marker {
    color: rgba($african-violet, 0.5);
  }
}





// Overview
.sp__overview {
  border-bottom: 12px solid $light-grey;
  margin-bottom: $space-third;
  padding: $space-and-three-quarters 0;

  .lead-paragraph {
    margin-bottom: 0;
  }
}





//
// Image
//
.sp__image {
  display: block;
  width: 100%;
}





//
// Card
//
.sp__card {
  margin: 0 auto $space;
  max-width: map-get($breakpoints, 'xs');

  @include media('≤xs-tweak') {
    .page-layout-wide-boxed & {
      margin: $space-and-half 0 0;
    }
  }

  @include media('≥xs-tweak') {
    .page-layout-wide-boxed & {
      width: percentage(math.div(3.5, 12));
    }
  }

  @include media('≥s') {
    width: percentage(math.div(5, 12));

    .page-layout-wide-boxed & {
      width: percentage(math.div(3.5, 12));
    }
  }

  @include media('≥l') {
    width: percentage(math.div(4, 12));

    .page-layout-wide-boxed & {
      position: absolute;
      right: 0;
      width: percentage(math.div(3.5, 12));
    }
  }
}

.sp__card-content {
  @include type-setting(2);

  background-color: $light-grey;
  color: $african-violet;
  font-family: $font-brand;
  padding: $space-half $space $space;

  @include media('≥s') {
    @include type-setting(1);
  }

  @include media('≥m', '<max') {
    @include type-setting(0);
  }
}





//
// Contact
//
.sp__contact-item {
  align-items: center;
  display: flex;
  margin: $space-half 0;
}

.sp__contact-icon {
  flex-shrink: 0;
  margin-right: $space-half;
  transition: transform 0.3s;

  @include media('≥s', '<max') {
    height: $space-three-quarters;
    width: $space-three-quarters;
  }
}

.sp__contact-svg {
  fill: rgba($african-violet, 0.5);
  transform: fill 0.3s;
}

.sp__contact-link {
  align-items: center;
  color: $african-violet;
  display: flex;
  text-decoration: underline 1px solid rgba($african-violet, 0.5);
  text-underline-offset: 4px;
  transition: text-decoration-color 0.3s;

  &:hover,
  &:focus {
    color: $mulberry;
    text-decoration-color: $mulberry;
    text-decoration-line: underline;

    .sp__contact-icon {
      transform: scale(1.15);
    }

    .sp__contact-svg {
      fill: $mulberry;
    }
  }
}





//
// Social
//
.sp__social-link {
  margin: $space-half 0;
}





//
// Research
//
.sp__research {
  border-bottom: 12px solid $light-grey;
  margin-bottom: $space;
  padding: $space 0;

  &.has-research-sec-1,
  &.has-research-sec-2,
  &.has-research-sec-3,
  &.has-research-ext-1,
  &.has-research-ext-2,
  &.has-research-ext-3,
  &:has(.sp__publications.has-button),
  &:has(.sp__publications.has-orcid-link),
  &:has(.sp__publications.has-researcher-link),
  &:has(.sp__publications.has-scholar-link) {
    @include media('≥s') {
      border-top: 4px solid $light-grey;
      margin-top: $space-and-half;
      //padding-top: $space-and-half;
    }
  }
}

.sp__research-heading {
  @include type-setting(3);

  font-family: $font-brand-medium;
  margin-bottom: $space-quarter;
}

.sp__research-groups {
  .has-button &,
  .has-orcid-link &,
  .has-researcher-link &,
  .has-scholar-link & {
    border-bottom: 4px solid $light-grey;
    margin-bottom: $space-and-quarter;
    padding-bottom: $space-and-quarter;
  }
}

.sp__research-groups-link {
  color: $african-violet;
  text-decoration: underline 1px solid $fountain-grey;
  text-underline-offset: 4px;
  transition: text-decoration-color 0.3s, color 0.3s;

  &:focus,
  &:hover {
    color: $mulberry;
    text-decoration-color: $mulberry;
    text-decoration-line: underline;
  }
}

.sp__research-link {
  align-items: center;
  color: $african-violet;
  display: inline-flex;
  margin: 0 $space-three-quarters $space-quarter 0;
  text-decoration: underline 1px solid $fountain-grey;
  text-underline-offset: 4px;
  transition: text-decoration-color 0.3s, color 0.3s;

  &:focus,
  &:hover {
    color: $mulberry;
    text-decoration-color: $mulberry;
    text-decoration-line: underline;

    .sp__research-link-icon {
      transform: scale(1.1);
    }

    .sp__research-link-svg-circle {
      fill: $mulberry;
    }
  }
}

.sp__research-link-icon {
  flex-shrink: 0;
  height: $space-and-three-quarters;
  margin-right: $space-third;
  transition: transform 0.3s;
  width: $space-and-three-quarters;
}

.sp__research-link-svg {
  border: 4px solid $light-grey;
  border-radius: 50%;
}

.sp__research-link-svg-circle {
  fill: $african-violet;
  transition: fill 0.3s;
}

.sp__research-link-text {
  font-family: $font-brand-medium;
}





//
// Publications
//
.sp__publications {
  &.has-button {
    &.has-orcid-link,
    &.has-researcher-link,
    &.has-scholar-link {
      border-bottom: 4px solid $light-grey;
      margin-bottom: $space-and-quarter;
      padding-bottom: $space-and-quarter;
    }
  }

  .button {
    background-color: $african-violet;
    margin: 0 $space-half $space-quarter 0;

    &:focus,
    &:hover {
      background-color: $mulberry;
    }
  }
}





//
// Tabs
//
//.content-type--button,
.content-type--tabs,
.content-type--main,
.content-type--toggle {
  @include media('≥l') {
    .page-staff-profile.page-layout-wide-boxed & {
      padding-right: $space-treble;
      width: percentage(math.div(8.5, 12));
    }
  }
}

.sp__alert {
  @include media('≥l') {
    .page-staff-profile.page-layout-wide-boxed & {
      width: percentage(math.div(7.75, 12));
    }
  }
}

.content-type--tabs {
  .page-staff-profile.page-layout-default .main-content & {
    padding-right: 0;
  }

  .content-type--main {
    @include media('≥l') {
      .page-staff-profile.page-layout-wide-boxed & {
        padding-right: 0;
        width: percentage(math.div(12, 12));
      }
    }
  }
}
