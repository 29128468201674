// -----------------------------------------------------------------------------
// This file contains all styles related to the Cookie Control component
// -----------------------------------------------------------------------------

//
// Font styles
// scss-lint:disable ImportantRule, IdSelector
//
#ccc {
  font-family: $font-regular !important; /* stylelint-disable-line declaration-no-important */


  // Override Fountain defaults
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-regular;
    letter-spacing: normal;
  }
}
