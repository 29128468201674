// -----------------------------------------------------------------------------
// This file contains all styles related to the embed component
// -----------------------------------------------------------------------------

.embed__iframe-wrapper {
  border: 1px solid $light-grey;
}





//
// Gecko form
//
.embed--gecko {
  margin-bottom: $space;
  margin-top: $space;

  .embed__iframe-wrapper {
    border: 0;
  }

  .embed__iframe {
    border: 0;
    width: 100%;
  }
}





//
// Embed heading
//
.embed__heading {
  @extend %sub-subheading;

  display: block;
  font-family: $font-brand;
  margin-bottom: $space-quarter;
  margin-top: $space-half;
}





//
// Embed content
//
.embed__content {
  @extend %module;
}





//
// Subtle and standout embed content
//
.embed__content--subtle,
.embed__content--standout {
  padding: $space-three-quarters $space $space;

  .embed__heading {
    margin-top: 0;
  }
}





//
// Embed content
//
.embed__content--subtle {
  background-color: $light-grey;
}





//
// Embed content
//
.embed__content--standout {
  background-color: $african-violet;
  color: $white;
}





//
// Content type container
//
.content-type--embed {
  margin-bottom: $space-double;
  margin-top: $space-double;
}
