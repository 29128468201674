// -----------------------------------------------------------------------------
// This file contains all styles related to the link component.
// -----------------------------------------------------------------------------

.link {
  display: inline-block;
  margin-right: $space-three-quarters;
  padding-bottom: $space-sixth;
  padding-top: $space-sixth;
  vertical-align: top;



  // Icon
  .icon {
    display: inline-block;
    vertical-align: middle;
  }

  .icon__svg {
    fill: $mulberry;
    transition: fill 0.3s;
  }



  // Hover and focus
  &:focus,
  &:hover {
    .icon__svg {
      fill: $african-violet;
    }
  }
}





// Link text
.link__text {
  display: inline-block;
  margin-right: $space-third;
  vertical-align: middle;
}





// Link with arrow
.link--arrow {
  .link__text {
    margin-right: $space-quarter;
    transition: color 0.3s, margin 0.3s;
  }

  &:hover,
  &:focus {
    .link__text {
      margin-right: $space-half;
    }
  }
}





// Link with icon on left
.link--icon-left {
  // Icon
  .icon {
    margin-right: $space-quarter;
  }



  // Text
  .link__text {
    margin-right: 0;
  }
}
