// -----------------------------------------------------------------------------
// This file contains all styles related to the image component.
// -----------------------------------------------------------------------------

.figure {
  margin: 0 0 $space;
}





//
// Figure image
//
.figure__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}





//
// Figure link
//
.figure__link {
  display: block;
  transition: transform 0.3s;

  &:hover,
  &:focus {
    transform: scale(1.05);
  }
}
