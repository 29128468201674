// -----------------------------------------------------------------------------
// This file contains all styles related to the image component.
// -----------------------------------------------------------------------------

.content-type--image {
  margin-bottom: $space;
}





//
// Alignment and sizing
//
.content-type--image-center {
  margin-left: auto;
  margin-right: auto;

  @include media('≥m') {
    padding-right: 0;
  }
}

.content-type--image-left,
.content-type--image-right {
  @include media('≥xs') {
    width: percentage(math.div(4, 12));
  }

  &.content-type--image-small {
    @include media('≥xs') {
      width: percentage(math.div(2, 12));
    }
  }

  &.content-type--image-large {
    @include media('≥xs') {
      width: percentage(math.div(5, 12));
    }
  }
}

.content-type--image-left {
  @include media('≥xs') {
    float: left;
    margin-right: $gutter;
  }
}

.content-type--image-right {
  @include media('≥xs') {
    float: right;
    margin-left: $gutter;
  }
}





//
// Lazy loading
//
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.3s;
}
