// -----------------------------------------------------------------------------
// This file contains all styles related to the callout component.
// -----------------------------------------------------------------------------

/**
 * Callout Content Padding
 *
 * Ensure that the callout's padding is consistent throughout implementations.
 **/
@mixin callout-content-padding($image-position: left) {
  $gap: right;

  @if right == $image-position {
    $gap: left;
  }

  padding: $space-and-half $space-and-half $space-and-three-quarters;

  @include media('≥l') {
    padding: $space-treble;
  }

  @include media('≥super-wide') {
    padding: $space-quadruple;
    padding-#{$gap}: 0;
  }
}

//
// Main callout styles
//
.callout {
  @extend %module;
  @include clearfix;

  background-color: $light-grey;
  margin: $space-double + $space-half (-$space-five-sixths);
  padding: $space-three-quarters $space-five-sixths $space-and-quarter;

  @include media('≥l') {
    margin-left: -$space-and-two-thirds;
    margin-right: -$space-and-two-thirds;
    padding: $space-and-quarter $space-and-two-thirds $space-and-half;
  }

  .editor {
    @include type-setting(1);

    font-family: $font-brand;
  }

  .media-link {
    display: block;
    margin-bottom: $space-three-quarters;
    margin-top: $space-third;

    @include media('≥xs') {
      width: 100%;
    }

    @include media('≥m') {
      float: left;
      width: percentage(math.div(4, 12));
    }
  }

  .media-link__container {
    @include media('≥xs') {
      padding-right: 0;
    }
  }

  &.has-image {
    @include clearfix;

    padding-top: $space-and-quarter;
  }


  // Button
  .button {
    margin-bottom: $space-quarter;
    margin-right: $space-quarter;
  }
}





//
// Callout image and video
//
.callout__image {
  display: block;
  margin-bottom: $space;
  width: 100%;

  @include media('≥xs') {
    float: left;
    margin-bottom: 0;
    margin-top: $space-third;
    padding-right: $gutter;
    width: percentage(math.div(4, 12));
  }
}





//
// Callout content
//
.callout__content {
  .has-image & {
    @include media('≥xs') {
      float: right;
      width: percentage(math.div(8, 12));
    }
  }

  .has-video & {
    @include media('≥m') {
      float: right;
      padding-left: $gutter;
      width: percentage(math.div(8, 12));
    }
  }
}





//
// Callout heading
//
.callout__heading {
  @extend %heading;

  color: $african-violet;
  margin-top: 0;
}





//
// Callout subheading
//
.callout__subheading {
  @extend %subheading;

  color: $african-violet;
  margin-top: 0;
}





//
// Standout callout
//
.callout--standout {
  background-color: $mulberry;
  color: $white;

  .callout__heading,
  .callout__subheading {
    color: $white;
  }


  // Editor styles
  .editor {
    a {
      border-bottom: 1px dotted $white;
      color: $white;

      &:hover,
      &:focus {
        border-bottom-style: solid;
      }
    }
  }



  // Button styles
  .button {
    background-color: $white;
    border-bottom: 0;
    color: $mulberry;

    .icon__svg {
      fill: $mulberry;
    }

    &:hover,
    &:focus {
      background-color: $african-violet;
      color: $white;

      .icon__svg {
        fill: $white;
      }
    }
  }
}





//
// Subtle callout
//
.callout--subtle {
  background-color: transparent;
  border: 1px solid lighten($asphalt, 50%);
}





//
// Profile callout
//
.callout--profile {
  .callout__heading,
  .callout__subheading {
    margin-bottom: 0;
  }


  // Editor
  .editor {
    @include type-setting(0);

    font-family: $font-regular;
    margin-bottom: $space-third;
  }

  .callout__heading + .editor {
    margin-top: $space-half;
  }

  .callout__subheading + .editor {
    margin-top: $space-three-quarters;
  }


  // Link
  .callout__link {
    color: $mulberry;
    display: inline-block;
    margin-bottom: $space-half;
    margin-right: $space-half;
    position: relative;

    .icon {
      background-color: $mulberry;
      display: inline-block;
      height: $space-and-half;
      left: 0;
      position: absolute;
      top: 0;
      transition: background-color 0.3s;
      vertical-align: top;
      width: $space-and-half;
    }

    .icon__svg {
      fill: $white;
      padding: $space-quarter;
    }

    &:hover,
    &:focus {
      .icon {
        background-color: $african-violet;
      }

      .callout__link-text {
        color: $african-violet;
      }
    }
  }


  // Link text
  .callout__link-text {
    background-color: $white;
    display: inline-block;
    min-height: $space-and-half;
    padding: $space-quarter $space-half $space-quarter $space-double;
    vertical-align: top;
  }


  // Standout profile callout
  &.callout--standout {
    // Callout link
    .callout__link {
      border-bottom: 0;
      color: $white;

      .icon {
        background-color: $white;
      }

      .icon__svg {
        fill: $mulberry;
      }

      &:hover,
      &:focus {
        .icon {
          background-color: $white;
        }

        .icon__svg {
          fill: $african-violet;
        }

        .callout__link-text {
          background-color: rgba($white, 0.2);
          color: $white;
        }
      }
    }

    .callout__link-text {
      background-color: rgba($white, 0.15);
    }
  }


  // Subtle profile callout
  &.callout--subtle {
    .callout__link {
      color: $mulberry;

      &:hover,
      &:focus {
        .callout__link-text {
          color: $african-violet;
        }
      }
    }

    .callout__link-text {
      background-color: $light-grey;
    }
  }
}





//
// Full width callout
//
.callout--full-width {
  background-color: $african-violet;
  color: $white;
  margin: 0;
  padding: 0;

  @include media('≥m') {
    display: flex;
  }

  .container & {
    @extend %full-width;
  }

  &.has-image {
    padding-top: 0;

    &::after {
      clear: unset;
      content: '';
      display: unset;
    }

    .callout__content {
      float: none;
      width: 100%;
    }
  }


  // Image and video
  .callout__image-wrapper,
  .callout__video-wrapper {
    @include media('≥m') {
      width: percentage(math.div(6, 12));
    }
  }

  .callout__video,
  .callout__image {
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    width: 100%;

    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
    }
  }


  // Video
  .callout__video {
    position: relative;

    .media-link__container {
      height: 100%;
    }

    .responsive-ratio--16by9 {
      height: 100%;
    }

    .caption {
      background-color: rgba($black, 0.68);
      bottom: 0;
      color: $white;
      left: 50%;
      padding: $space-half;
      position: absolute;
      transform: translate(-50%, 0);
      width: 100%;

      @include media('≥m') {
        padding: $space-three-quarters $space;
      }
    }
  }


  // Content
  .callout__content {
    @include callout-content-padding();

    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: map-get($breakpoints, 's-tweak');

    @include media('≥m') {
      width: percentage(math.div(6, 12));
    }
  }

  .callout__heading {
    color: $white;
    font-family: $font-brand-bold;

    @include media('≥l') {
      margin-bottom: $space;
    }
  }

  .editor {
    @include type-setting(1);

    @include media('≥l') {
      @include type-setting(2);

      line-height: 1.5;
      margin-bottom: $space-two-thirds;
    }


    // Links
    a {
      border-bottom: 1px dotted $white;
      color: $white;

      &:hover,
      &:focus {
        border-bottom-style: solid;
      }
    }
  }


  // Button
  .button {
    background-color: $white;
    color: $african-violet;

    .icon__svg {
      fill: $african-violet;
    }

    &:hover,
    &:focus {
      background-color: $african-violet-light;
      color: $white;

      .icon__svg {
        fill: $white;
      }
    }
  }
}





//
// Image Right, Content Left
//
.callout--image-right {
  @include media('≥m') {
    display: flex;
    justify-content: flex-end;
  }

  .callout__image-wrapper {
    order: 2;
  }

  .callout__content {
    @include callout-content-padding(right);

    order: 1;
  }
}





//
// Spacing between content types
//
.content-type--callout + .content-type--callout {
  margin-top: -$space;
}

.content-type--lead-paragraph + .content-type--callout .callout {
  margin-top: $space-and-half;
}

.content-type--heading + .content-type--callout .callout {
  margin-top: 0;
}
