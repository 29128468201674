// -----------------------------------------------------------------------------
// This file contains all styles related to the quote component.
// -----------------------------------------------------------------------------

.parallax {
  @extend %module;
  @include clearfix;

  background-position: center top;
  background-size: cover;
  padding-bottom: $space-double;
  padding-top: $space-double;
  position: relative;

  @include media('≥l') {
    background-attachment: fixed;
    background-position: center center;
  }

  .button {
    margin-bottom: $space-half;
    margin-right: $space-half;
  }


  // Parallax enabled
  &.is-enabled {
    color: $white;
    padding-bottom: $space-quadruple;
    padding-top: $space-quadruple;

    &::before {
      background-color: rgba($black, 0.5);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }


  // Parallax disabled
  &.has-image .container,
  &.has-video .container {
    @include media('≥s') {
      align-items: center;
      display: flex;
    }
  }


  // Make last parallax item flush with global footer
  &:last-of-type {
    margin-bottom: -$space-five-sixths;

    @include media('≥m') {
      margin-bottom: -$space-quadruple;
    }
  }


  // In wide boxed pages
  .page-layout-wide-boxed & {
    @extend %full-width;
  }


  // In default page layouts
  .page-layout-default & {
    @include media('≥m') {
      margin-right: $gutter;
    }

    // Container
    .container {
      @include media('≥max') {
        padding-left: $space-and-third;
      }
    }
  }
}





//
// Content
//
.parallax__content {
  position: relative;
  z-index: 10;

  @include media('≥s') {
    width: percentage(math.div(8, 12));
  }

  @include media('≥l') {
    width: percentage(math.div(7, 12));
  }


  // Has image or video
  .has-image &,
  .has-video & {
    @include media('≥s') {
      padding-left: $gutter;
    }

    @include media('≥l') {
      padding-left: $gutter * 1.5;
    }
  }


  // Has video and parallax enabled
  .has-video.is-enabled & {
    @include media('≥s') {
      padding-left: 0;
      padding-right: $gutter;
    }

    @include media('≥l') {
      order: 1;
      padding-left: 0;
      padding-right: $gutter * 1.5;
    }
  }
}





//
// Parallax image and video
//
.parallax__image,
.parallax__video {
  margin-bottom: $space-half;
  position: relative;
  z-index: 10;

  @include media('≥s') {
    margin-bottom: 0;
    margin-top: $space-quarter;
    width: percentage(math.div(4, 12));
  }

  @include media('≥l') {
    margin-top: 0;
    width: percentage(math.div(5, 12));
  }
}





//
// Parallax video
//
.parallax__video {
  display: block;


  // Icon
  .icon {
    background-color: rgba($black, 0.3);
    border-radius: 50%;
    height: $space-double;
    left: 50%;
    margin-left: -$space;
    margin-top: -$space;
    position: absolute;
    top: 50%;
    transition: transform 0.3s;
    width: $space-double;
    z-index: 10;

    @include media('≥s') {
      height: $space-double + $space-half;
      margin-left: -$space-and-quarter;
      margin-top: -$space-and-quarter;
      width: $space-double + $space-half;
    }


    // When enabled
    .is-enabled & {
      display: block;
      left: 0;
      margin-left: 0;
      margin-top: 0;
      position: relative;
      top: 0;

      @include media('≥s') {
        height: $space-treble;
        width: $space-treble;
      }

      @include media('≥m') {
        height: $space-quadruple;
        margin-left: auto;
        margin-right: auto;
        width: $space-quadruple;
      }
    }
  }

  .icon__svg {
    fill: $white;
  }


  // Hover and focus
  &:hover,
  &:focus {
    .icon {
      transform: scale(1.25);
    }
  }


  // Is enabled
  .is-enabled & {
    @include media('≥s') {
      order: 2;
    }
  }
}





//
// Image
//
.parallax__video-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;


  // Hide when parallax is not enabled
  .is-enabled & {
    display: none;
  }
}





//
// Parallax image container
//
.parallax__video-image-container {
  .is-enabled & {
    height: auto;
    overflow: visible;
    padding: 0;
  }
}





//
// Parallax heading
//
.parallax__heading {
  @include type-setting(6);

  .is-enabled & {
    @include type-setting(7);
  }
}





//
// Parallax text
//
.parallax__text {
  margin-bottom: $space;

  .is-enabled & {
    @include type-setting(2);
  }
}
