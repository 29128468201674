// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// DIN
@font-face {
  font-display: swap;
  font-family: 'DIN';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Light';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-light.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-light.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Medium';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-medium.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-medium.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Bold';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-bold.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Black';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-black.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-black.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-black.ttf') format('truetype'); /* Safari, Android, iOS */
}





// DIN Round
@font-face {
  font-display: swap;
  font-family: 'DIN Round';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Round Light';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-light.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-light.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Round Medium';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-medium.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-medium.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Round Bold';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-bold.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Round Black';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-black.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-black.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-round-black.ttf') format('truetype'); /* Safari, Android, iOS */
}





// DIN Condensed
@font-face {
  font-display: swap;
  font-family: 'DIN Condensed';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Condensed Light';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-light.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-light.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Condensed Medium';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-medium.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-medium.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Condensed Bold';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-bold.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: 'DIN Condensed Black';
  src: url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-black.eot'); /* IE9 Compat Modes */
  src:
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-black.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://www.lboro.ac.uk/web-assets/shared/fonts/din-cond-black.ttf') format('truetype'); /* Safari, Android, iOS */
}
