// -----------------------------------------------------------------------------
// This file contains all styles related to the list component.
// -----------------------------------------------------------------------------

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}





//
// Skip links
//
.list--skip-links {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 5;

  .list__link {
    background-color: $black;
    color: $white;
    display: inline-block;
    left: 50%;
    margin-left: -125px;
    padding: $space-half;
    position: absolute;
    top: -9999px;
    width: 250px;

    &:focus {
      top: $space;
    }
  }
}





//
// Contact
//
.list--contact {
  .list__item {
    margin-bottom: $space-quarter;
    padding-left: $space-and-quarter;
    position: relative;

    @include media('≥l') {
      padding-left: $space-and-half;
    }
  }

  .icon {
    left: 0;
    position: absolute;
    top: 0;

    @include media('<l') {
      height: $space-three-quarters;
      width: $space-three-quarters;
    }
  }

  .icon__svg {
    fill: $asphalt;
  }
}





//
// Bordered
//
.list--bordered {
  border-top: 1px solid $fountain-grey;

  .list__item {
    @include type-setting(1);

    border-bottom: 1px solid $fountain-grey;
    margin-bottom: 0;
    padding-bottom: $space-quarter;
    padding-top: $space-quarter;
  }
}





//
// News listing
//
.list--news-listing {
  // List item
  .list__item {
    margin-bottom: $space-and-half;

    &.has-image {
      .list__image-wrapper {
        background-image: none;
      }
    }
  }


  // Image
  .list__image-wrapper {
    background-color: $light-grey;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }


  // Link
  .list__link {
    @include media('≥s') {
      align-items: flex-start;
      display: flex;
    }

    &:hover,
    &:focus {
      .list__image {
        transform: scale(1.15);
      }

      .list__text {
        color: $text-color-lightest;
      }
    }
  }


  // Image and text
  .list__content--image {
    margin-bottom: $space-two-thirds;
    width: 100%;

    @include media('≥s') {
      margin-bottom: 0;
      margin-top: $space-third;
      padding-right: $gutter;
      width: percentage(math.div(3, 12));
    }
  }

  .list__content--text {
    @include media('≥s') {
      width: percentage(math.div(9, 12));
    }
  }


  // Image
  .list__image {
    transition: transform 0.6s;
  }


  // Heading
  .list__heading {
    @include type-setting(3);

    font-family: $font-brand-medium;
    margin-bottom: $space-half;

    @include media('≥s') {
      margin-bottom: $space-quarter;
    }
  }


  // Text
  .list__text {
    color: $text-color;
    margin-bottom: $space-half;

    @include media('≥s') {
      margin-bottom: $space-quarter;
    }
  }

  .list__text--date {
    @include type-setting(-1);

    color: $text-color-lightest;
    margin-bottom: 0;
  }
}





//
// News categories listing
//
.list--news-categories {
  @include clearfix;
  @include type-setting(2);

  font-family: $font-brand-medium;
  margin-bottom: $space-and-half;

  .list__item {
    margin-bottom: $space-half;
    width: 100%;

    @include media('≥m') {
      float: left;
      margin-bottom: 0;
      padding: $gutter-sixth;
      width: percentage(math.div(6, 12));
    }
  }

  a {
    background-color: $light-grey;
    display: block;
    padding: $space-two-thirds $space-three-quarters $space-half;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;

    &:hover,
    &:focus {
      background-color: $african-violet;
      color: $white;
      transform: scale(1.025);
      z-index: 10;

      .list__text {
        color: $white;
        opacity: 0.6;
      }
    }
  }
}





//
// Staff listing
//
.list--staff {
  // List item
  .list__item {
    border-bottom: 1px solid $fountain-grey;
    display: block;
    padding: $space-two-thirds $space-half $space-half;
    width: 100%;

    @include media('≥s') {
      padding: $space-three-quarters $space;
    }
  }


  // Content container
  .list__content {
    @include media('≥s') {
      align-items: center;
      display: flex;
    }
  }


  // Name and role container
  .list__name-role {
    margin-bottom: $space-quarter;

    @include media('≥s') {
      width: percentage(math.div(5, 12));
    }

    @include media('≥l') {
      width: percentage(math.div(6, 12));
    }
  }


  // Contact details container
  .list__contact {
    @include media('≥s') {
      display: flex;
      justify-content: flex-end;
      width: percentage(math.div(7, 12));
    }

    @include media('≥l') {
      width: percentage(math.div(6, 12));
    }
  }


  // Main link on member of staff's name
  .list__link {
    @include type-setting(3);

    align-items: center;
    display: flex;
    font-family: $font-brand;
    padding-right: $space;
    position: relative;


    // Link text
    .list__link-text {
      padding-right: $space-third;
    }


    // Link icon {
    .icon {
      flex-shrink: 0;
      height: $space-two-thirds;
      width: $space-two-thirds;
    }

    .icon__svg {
      fill: $mulberry;
      transition: fill 0.3s;
    }


    // Hover and focus
    &:hover,
    &:focus {
      .icon__svg {
        fill: $african-violet;
      }
    }
  }


  // Contact links
  .link {
    .icon {
      @include media('<m') {
        height: $space-three-quarters;
        width: $space-three-quarters;
      }
    }
  }

  .link--email {
    margin-right: 0;
  }


  // Staff list in default page layout
  .page-layout-default & {
    @include media('≥m') {
      margin-right: $gutter;
    }
  }


  // Featured member of staff
  .list__item--featured {
    background-color: $african-violet;
    border-bottom: 1px solid $white;


    // Links
    .list__link,
    .link {
      color: $white;

      // Hover and focus
      &:hover,
      &:focus {
        .list__role {
          color: $white;
        }

        .icon__svg {
          fill: $white;
        }
      }
    }

    .list__link {
      @include type-setting(4);
    }

    // Pronouns
    .list__pronouns {
      color: rgba($white, 0.9);
    }


    // Role
    .list__role {
      @include type-setting(1);

      color: rgba($white, 0.8);
    }


    // Icon
    .icon__svg {
      fill: rgba($white, 0.6);
    }
  }
}





//
// Section iterator
//
.list--section-iterator {
  align-items: center;
  display: flex;
  margin-top: $space-double;
  width: 100%;


  // Previous link
  .list__item--prev {
    a {
      padding-left: $space-and-half;

      &::before {
        background-image: encode-svg('<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M.045 7.83C.04 7.82.032 7.814.028 7.805a.33.33 0 0 1 0-.268c.003-.01.01-.016.016-.026a.302.302 0 0 1 .062-.08l.005-.01L8.45.09a.333.333 0 1 1 .44.5L1.22 7.34h14.45a.334.334 0 0 1 0 .667H1.218l7.67 6.75a.332.332 0 0 1-.22.583.33.33 0 0 1-.22-.083L.11 7.922c-.002 0-.003-.005-.005-.007a.348.348 0 0 1-.062-.083V7.83z" fill="#{$mulberry}" /></svg>');
        left: $space-half;
      }
    }
  }


  // Next link
  .list__item--next {
    margin-left: auto;

    a {
      padding-right: $space-and-half;

      &::before {
        background-image: encode-svg('<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M15.954 7.83c.005-.01.013-.016.017-.025a.33.33 0 0 0 0-.268c0-.01-.01-.016-.013-.026a.302.302 0 0 0-.062-.08l-.005-.01L7.552.09a.333.333 0 1 0-.44.5l7.67 6.75H.332a.333.333 0 0 0 0 .667h14.45l-7.67 6.75a.332.332 0 0 0 .22.583.33.33 0 0 0 .22-.083l8.335-7.333c.002 0 .003-.005.005-.007a.348.348 0 0 0 .062-.083z" fill="#{$mulberry}" /></svg>');
        right: $space-half;
      }
    }
  }


  // Styling of links (can't use class as markup defined by T4 nav object)
  a {
    align-items: center;
    background-color: $light-grey;
    border: 1px solid $light-grey;
    display: flex;
    display: inline-block;
    padding: $space-half $space-half ($space-half + $space-sixth);
    position: relative;

    &:focus,
    &:hover {
      border-color: lighten($fountain-grey, 8%);
      color: $mulberry;
    }


    // Arrow icon
    &::before {
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: $space-two-thirds;
      margin-top: -$space-third;
      position: absolute;
      top: 50%;
      width: $space-two-thirds;
    }
  }
}





//
// Contact list
//
.list--footer-contact {
  text-align: center;

  @include media('≥xs') {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include media('≥m') {
    flex-wrap: nowrap;
  }


  // Icon
  .icon {
    height: $space-double;
    margin-bottom: $space-three-quarters;
    position: relative;
    top: 0;
    transition: top 0.3s;
    width: $space-double;

    @include media('≥l') {
      height: $space-treble;
      width: $space-treble;
    }
  }

  .icon__svg {
    fill: $white;
  }


  // List item
  .list__item {
    margin-bottom: $space-half;

    @include media('≥xs') {
      display: flex;
      padding-left: $space-quarter;
      padding-right: $space-quarter;
      width: percentage(math.div(6, 12));
    }

    @include media('≥l') {
      padding-left: $space-half;
      padding-right: $space-half;
      width: percentage(math.div(3, 12));
    }
  }

  .list__item--email {
    .list__text {
      @include type-setting(0);
    }
  }


  // Heading
  .list__heading {
    @include type-setting(4);

    display: block;
    margin-bottom: $space-sixth;
  }


  // List subheading
  .list__subheading {
    @include type-setting(2);

    margin-bottom: 0;
    opacity: 0.8;
  }


  // Text
  .list__text {
    opacity: 0.8;
  }


  // Link
  .list__link {
    background-color: rgba($black, 0.2);
    color: $white;
    display: block;
    overflow-wrap: break-word;
    padding: $space $space-three-quarters;
    transition: background-color 0.3s;
    word-wrap: break-word;

    @include media('≥xs') {
      flex-grow: 1;
    }

    @include media('≥l') {
      padding: $space-and-quarter $space;
    }

    &:hover,
    &:focus {
      background-color: rgba($black, 0.3);

      .icon {
        top: -$space-quarter;
      }
    }
  }
}
