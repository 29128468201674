// -----------------------------------------------------------------------------
// This file contains all styles related to the rich box component.
// -----------------------------------------------------------------------------

.rich-box {
  color: $white;
  display: block;
  margin-bottom: $space-eighth;
  padding-left: math.div($space-eighth, 2);
  padding-right: math.div($space-eighth, 2);
  text-align: center;
  vertical-align: top;


  // SVG icon
  .icon__svg {
    fill: $white;
  }


  // UI icon
  .icon--ui {
    height: $space-two-thirds;
    position: absolute;
    right: $space-two-thirds;
    top: $space-two-thirds;
    transition: right 0.3s;
    width: $space-two-thirds;

    @include media('≥l') {
      right: $space-three-quarters;
      top: $space-three-quarters;
    }
  }


  // Width
  &.width {
    &--25 {
      @include media('≥s') {
        display: inline-block;
        vertical-align: top;
        width: percentage(math.div(6, 12));
      }

      @include media('≥l') {
        width: percentage(math.div(3, 12));
      }
    }

    &--33 {
      @include media('≥m') {
        display: inline-block;
        vertical-align: top;
        width: percentage(math.div(4, 12));
      }
    }

    &--50 {
      @include media('≥m') {
        display: inline-block;
        vertical-align: top;
        width: percentage(math.div(6, 12));
      }
    }

    &--66 {
      @include media('≥m') {
        display: inline-block;
        vertical-align: top;
        width: percentage(math.div(8, 12));
      }
    }
  }
}





//
// Container
//
.rich-box__container {
  background-position: center center;
  background-size: cover;
  padding: $space;
  position: relative;

  @include media('≥l') {
    padding: $space-double $space-treble;
  }


  // Background overlay
  &::before {
    background-color: rgba($black, 0.25);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.3s;
    width: 100%;
    z-index: 0;
  }


  // Has link
  .has-link & {
    padding: 0;

    &::before {
      display: none;
    }
  }


  // Hide inline background image if colour chosen
  &.background-color {
    background-image: none !important; /* stylelint-disable-line declaration-no-important */
  }


  // Light grey and white background colour overrides
  &.background-color--white,
  &.background-color--light-grey {
    color: $asphalt;


    // Icon
    .icon__svg {
      fill: $asphalt;
    }


    // Hide overlay
    &::before {
      display: none;
    }
  }


  // White background colour overrides
  &.background-color--white {
    border: 1px solid $fountain-grey;
  }


  // Reduce padding at narrower widths
  .width--25 &,
  .width--33 & {
    @include media('≥l') {
      padding-left: $space;
      padding-right: $space;
    }
  }
}





//
// Main icon
//
.rich-box__icon-container {
  .icon {
    height: $space-treble;
    margin-bottom: $space-two-thirds;
    position: relative;
    top: 0;
    transition: top 0.3s;
    width: 100%;
    z-index: 10;

    @include media('≥l') {
      height: $space-quadruple;
    }
  }
}





//
// Link
//
.rich-box__link {
  color: $white;
  display: block;
  padding: $space;

  @include media('≥l') {
    padding: $space-double $space-treble;
  }


  // Background overlay
  &::before {
    background-color: rgba($black, 0.4);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.3s;
    width: 100%;
    z-index: 0;
  }


  // Reduce left and right padding for narrow rich box widths
  .width--25 &,
  .width--33 & {
    @include media('≥l') {
      padding-left: $space-three-quarters;
      padding-right: $space-three-quarters;
    }
  }


  // Light grey and white background colour overrides
  .background-color--white &,
  .background-color--light-grey & {
    color: $asphalt;


    // Hide overlay initially
    &::before {
      background-color: rgba($black, 0);
    }
  }


  // Hover and focus styles
  &:hover,
  &:focus {
    color: $white;


    // Move main icon up slightly
    .icon:not(.icon--ui) {
      top: -$space-third;
    }

    .background-color--light-grey &,
    .background-color--white & {
      color: $asphalt;
    }


    // Increase opacity of background overlay
    &::before {
      background-color: rgba($african-violet, 0.5);

      .background-color & {
        background-color: rgba($black, 0.6);
      }

      .background-color--light-grey &,
      .background-color--white & {
        background-color: rgba($black, 0.04);
        color: $asphalt;
      }
    }
  }
}





//
// Heading
//
.rich-box__heading {
  @include type-setting(6);

  position: relative;
  z-index: 10;

  .has-link & {
    padding-left: $space;
    padding-right: $space;
  }


  // Width styling
  .width--25 &,
  .width--33 & {
    @include media('≥l') {
      @include type-setting(4);
    }
  }

  .has-link.width--25 &,
  .has-link.width--33 & {
    @include media('≥l') {
      padding-left: 0;
      padding-right: 0;
    }
  }
}





//
// Subheading
//
.rich-box__subheading {
  @include type-setting(4);

  position: relative;
  z-index: 10;

  .has-link & {
    padding-left: $space;
    padding-right: $space;
  }


  // Width styling
  .width--25 &,
  .width--33 & {
    @include media('≥l') {
      @include type-setting(3);
    }
  }
}





//
// Text
//
.rich-box__text {
  @include type-setting(2);

  margin-bottom: 0;
  position: relative;
  z-index: 10;


  // Width styling
  .width--25 &,
  .width--33 & {
    @include type-setting(1);
  }
}





//
// Contact details
//
.rich-box__contact {
  @include type-setting(0);

  margin-top: $space;
  position: relative;
  z-index: 10;


  // List definition
  .list__item--definition {
    margin-left: 0;
  }


  // Link
  .list__link {
    background-color: rgba($black, 0.1);
    color: $white;
    display: block;
    margin-bottom: $space-eighth;
    padding: $space-quarter $space-quarter $space-third;

    .background-color--light-grey &,
    .background-color--white & {
      background-color: rgba($black, 0.05);
      color: $asphalt;
    }

    &:focus,
    &:hover {
      background-color: rgba($black, 0.2);

      .background-color--light-grey &,
      .background-color--white & {
        background-color: rgba($black, 0.085);
      }
    }
  }

  .list__link-text {
    display: inline-block;
    vertical-align: middle;
  }


  // Icon
  .icon {
    display: inline-block;
    height: $space-three-quarters;
    margin-right: $space-quarter;
    vertical-align: middle;
    width: $space-three-quarters;
  }
}
