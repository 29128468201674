// -----------------------------------------------------------------------------
// This file contains all styles related to the link component.
// -----------------------------------------------------------------------------

.media-link {
  display: block;
  margin-bottom: $gutter-half;
  margin-top: $gutter-quarter;

  @include media('≥xs') {
    display: inline-block;
    padding-left: $gutter-half;
    padding-right: $gutter-half;
    vertical-align: top;
    width: percentage(math.div(6, 12));
  }

  @include media('≥l') {
    width: percentage(math.div(4, 12));
  }


  // In default page layout
  .page-layout-default & {
    @include media('≥m') {
      padding-left: 0;
      padding-right: $gutter;
    }
  }


  // Alignment
  &.align {
    margin-bottom: $space;
    margin-top: 0;

    @include media('≥xs', '<l') {
      display: block;
      width: 100%;
    }

    @include media('≥s') {
      margin-bottom: $gutter;
    }
  }

  &.align--center {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include media('≥l') {
      padding-right: 0;
    }
  }

  &.align--left {
    @include media('≥s') {
      float: left;
    }
  }

  &.align--right {
    @include media('≥s') {
      float: right;
      margin-left: $gutter;
      padding-left: 0;
    }
  }


  // Widths
  &.width--25 {
    @include media('≥l') {
      width: percentage(math.div(3, 12));
    }
  }

  &.width--50 {
    @include media('≥l') {
      width: percentage(math.div(6, 12));
    }
  }

  &.width--66 {
    @include media('≥l') {
      width: percentage(math.div(8, 12));
    }
  }

  &.width--75 {
    @include media('≥l') {
      width: percentage(math.div(9, 12));
    }
  }

  &.width--100 {
    @include media('≥l') {
      width: 100%;
    }
  }
}





//
// Icon
//
.media-link__icon {
  background-color: rgba($black, 0.3);
  border-radius: 50%;
  height: $space-double;
  left: 50%;
  margin-left: -$space;
  margin-top: -$space;
  position: absolute;
  top: 50%;
  transition: transform 0.3s;
  width: $space-double;
  z-index: 10;

  @include media('≥s') {
    height: $space-double + $space-half;
    margin-left: -$space-and-quarter;
    margin-top: -$space-and-quarter;
    width: $space-double + $space-half;
  }

  .width--66 &,
  .width--75 &,
  .width--100 & {
    @include media('≥s') {
      height: $space-treble;
      margin-left: -$space-and-half;
      margin-top: -$space-and-half;
      width: $space-treble;
    }
  }
}

.media-link__svg {
  fill: $white;
}





//
// Video media link
//
.media-link--video {
  &:hover,
  &:focus {
    .media-link__icon {
      transform: scale(1.05);
    }
  }

  .media-link__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}





//
// Virtual tour link
//
.media-link--virtual-tour {
  .media-link__image-container {
    &::before {
      background-color: rgba($black, 0.25);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
    }
  }

  .media-link__icon {
    background-color: transparent;
  }
}





//
// Media link link
//
.media-link__link {
  display: block;

  &:hover,
  &:focus {
    .media-link__icon {
      transform: scale(1.05);
    }
  }
}





//
// High resolution download
//
.media-link--has-download {
  margin-bottom: $space-and-two-thirds;
}

.media-link__download {
  border-top: 1px solid $fountain-grey;
  font-family: $font-brand;
  margin-top: $space-half;
  padding-top: $space-half;
}


// Download icon
.media-link__download-icon {
  flex-shrink: 0;
  margin-right: $space-quarter;
}

.media-link__download-svg {
  fill: $mulberry;
}


// Download link
.media-link__download-link {
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover,
  &:focus {
    .media-link__download-svg {
      fill: $african-violet;
    }
  }
}
