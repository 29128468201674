// -----------------------------------------------------------------------------
// This file contains all styles related to the banner component
// -----------------------------------------------------------------------------

.banner {
  margin-bottom: 0;
  position: relative;
  transition: margin-bottom 0.1s;

  .button--play {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    &:hover,
    &:focus {
      .icon--ui-play {
        transform: scale(1.2);
      }
    }
  }

  .icon--ui-play {
    background-color: rgba($black, 0.15);
    border-radius: 50%;
    height: $space-quadruple;
    left: 50%;
    margin-left: -$space-double;
    margin-top: -$space-double;
    position: absolute;
    top: 50%;
    transition: transform 0.3s;
    width: $space-quadruple;

    @include media('≥l') {
      height: $space * 5;
      margin-left: -($space-double + $space-half);
      margin-top: -($space-double + $space-half);
      width: $space * 5;
    }

    .has-intro & {
      @include media('≥m') {
        margin-top: -$space-treble;
      }

      @include media('≥l') {
        margin-top: -($space-treble + $space-half);
      }

      @include media('≥wide') {
        margin-top: -($space-quadruple - $space-sixth);
      }
    }
  }

  &.video-active {
    .button--play {
      display: none;
    }
  }
}





//
// Image wrapper
//
.banner__image-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  transition: height 0.3s, padding-bottom 0.3s;

  @include media('≥s') {
    padding-bottom: 40.625%;
  }

  @include media('≥m') {
    padding-bottom: 25%;
  }

  @include media('≥wide') {
    height: 450px;
    padding: 0;
  }

  .has-callout & {
    @include media('≥m') {
      padding-bottom: 35%;
    }

    @include media('≥wide') {
      height: 500px;
      padding: 0;
    }
  }

  .has-video & {
    background-color: $black;
  }

  .video-active & {
    height: auto;
    padding-bottom: 0;
  }
}





//
// Image
//
.banner__image {
  background-position: center center;
  background-size: cover;
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity 1s;
  width: 100%;
  z-index: 0;

  .video-active & {
    opacity: 0;
  }
}

.banner__image--bottom {
  @include media('≥wide') {
    background-position: center bottom;
  }
}

.banner__image--top {
  @include media('≥wide') {
    background-position: center top;
  }
}





//
// Main callout styles
//
.banner__callout {
  color: $white;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 10;

  @include media('≥m') {
    align-items: center;
    background-color: rgba($dark-grey, 0.65);
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  @include media('≥wide') {
    background-color: transparent;
    height: auto;
  }

  .container {
    background-color: $african-violet;
    padding: $space-three-quarters $space $space;
    position: relative;

    @include media('≥m') {
      background-color: transparent;
      padding: $space-three-quarters $space-double $space;
    }

    @include media('≥wide') {
      padding: 0;
    }
  }

  .button {
    margin-top: $space;

    .icon__svg {
      fill: $white;
    }

    &:focus,
    &:hover {
      @include media('<m') {
        background-color: $african-violet-light;
      }
    }
  }
}

.banner__callout-content {
  @extend %module;
}

.banner__callout-heading {
  @include type-setting(6);

  @include media('≥s') {
    @include type-setting(7);
  }

  @include media('≥m') {
    @include type-setting(8);
  }
}

.banner__callout-text {
  @include type-setting(2);

  font-family: $font-brand;
}





//
// Default callout styles
//
.banner__callout--default {
  @include media('≥wide') {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .container {
    @include media('≥wide') {
      background-color: rgba($dark-grey, 0.65);
      padding: $space $space-double;
    }
  }
}




//
// Bottom callout styles
//
.banner__callout--bottom {
  @include media('≥wide') {
    background-color: rgba($dark-grey, 0.65);
    bottom: 0;
    padding-bottom: $space-and-half;
    padding-top: $space;
    text-align: left;
    top: auto;
  }

  .banner__callout-content {
    @include media('≥wide') {
      float: left;
      width: percentage(math.div(8, 12));
    }
  }

  .banner__callout-text {
    @include media('≥wide') {
      margin-bottom: 0;
    }
  }

  .button {
    @include media('≥wide') {
      bottom: 0;
      margin-top: 0;
      position: absolute;
      right: 0;
      width: percentage(math.div(3, 12));
    }
  }
}





//
// Right callout styles
//
.banner__callout--right {
  @include media('≥m') {
    height: 100%;
    text-align: right;
    top: 0;
  }

  .container {
    @include media('≥wide') {
      background-color: rgba($dark-grey, 0.65);
      margin: 0 15% 0 35%;
      padding: $space $space $space-and-sixth;
      width: percentage(math.div(6, 12));
    }
  }
}





//
// Video
//
.banner__video {
  background-color: $black;
  height: 0;
  opacity: 0;
  padding: $space-double $space-five-sixths;
  position: relative;
  text-align: center;
  transition: height 0.3s opacity 0.3s;
  visibility: hidden;
  z-index: 0;

  .embed {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
    position: relative;
  }

  .embed__iframe-wrapper {
    border: 0;
  }

  .button--close {
    height: $space-two-thirds;
    position: absolute;
    right: $space;
    top: $space-two-thirds;
    transition: transform 0.3s;
    width: $space-two-thirds;

    @include media('≥ultra-wide') {
      height: $space;
      right: $space-double;
      top: $space-double;
      width: $space;
    }

    .icon {
      height: 100%;
      width: 100%;
    }

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }

  .video-active & {
    height: auto;
    opacity: 1;
    visibility: visible;
    z-index: 20;
  }
}
