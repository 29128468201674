// -----------------------------------------------------------------------------
// This file contains all styles related to the app link
// -----------------------------------------------------------------------------

//
// Outer container
//
.app-links {
  margin-bottom: $space;
  text-align: center;

  @include media('≥xs') {
    text-align: left;
  }
}




//
// Link
//
.app-link {
  display: block;
  margin-bottom: $space-half;
  transition: transform 0.3s;

  @include media('≥xs') {
    display: inline-block;
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    transform: scale(1.075);
  }
}





//
// App Store link
//
.app-link--app-store {
  @include media('≥xs') {
    margin-right: $space-half;
  }
}





//
// Image
//
.app-link__image {
  height: $space-double;
}
