// -----------------------------------------------------------------------------
// This file contains all layout styles related to the main content area
// -----------------------------------------------------------------------------

//
// Main content styles and component specific styling
//
.main-content {
  padding-top: $space;

  @include media('≥m') {
    float: left;
    padding-top: $space-double;
    width: percentage(math.div(8.25, 12));
  }

  @include media('≥l') {
    width: percentage(math.div(8.25, 12));
  }


  // Make full width in wide page layouts
  .page-layout-wide &,
  .page-layout-wide-boxed & {
    @include media('≥m') {
      float: none;
      width: 100%;
    }
  }


  // When the page is narrow
  .page-narrow & > .container {
    @include media('≥l') {
      padding-left: percentage(math.div(1, 12));
      padding-right: percentage(math.div(1, 12));
    }
  }


  // When the page has a banner
  .has-banner & {
    padding-top: $space;

    @include media('≥m') {
      padding-top: $space-and-half;
    }
  }


  // In home page layout
  .page-layout-home & {
    padding-top: 0;
  }
}





//
// Columns
//
.cols {
  @include media('≥s') {
    display: flex;
  }
}

.col {
  margin-bottom: $space;

  @include media('≥s') {
    margin-bottom: $gutter;
    padding-left: $gutter-half;
    padding-right: $gutter-half;
    width: percentage(math.div(6, 12));
  }


  // Widths
  &--25 {
    @include media('≥l') {
      width: percentage(math.div(3, 12));
    }
  }

  &--33 {
    @include media('≥l') {
      width: percentage(math.div(4, 12));
    }
  }

  &--66 {
    @include media('≥l') {
      width: percentage(math.div(8, 12));
    }
  }

  &--75 {
    @include media('≥l') {
      width: percentage(math.div(9, 12));
    }
  }
}
