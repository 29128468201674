// -----------------------------------------------------------------------------
// This file contains styles that are specific to the news article page.
// -----------------------------------------------------------------------------

// Heading
.news-article__heading {
  @include type-setting(7);

  color: $african-violet;
  margin-bottom: $space-half;
  margin-right: $space-quarter;
}





// Meta (date and categories)
.news-article__meta {
  margin-bottom: $space;
}

.news-article__date {
  @include type-setting(1);

  color: $text-color-lightest;
  display: inline-block;
  font-family: $font-brand;
  margin-bottom: $space-half;
  margin-right: $space-half;
  vertical-align: middle;
}

.news-article__categories {
  display: inline-block;
  vertical-align: middle;

  .button {
    @include type-setting(-1);

    margin-bottom: $space-half;
    margin-right: $space-half;
  }
}





// Main image
.news-article__image-wrapper {
  margin-bottom: $space-and-third;
}

.news-article__image {
  display: block;
}

.news-article__image-caption {
  background-color: $light-grey;
  padding: $space-two-thirds;
}





// Secondary image
.page-layout-default .news-article .content-type--image {
  @include media('≥m') {
    padding-right: 0;
  }
}
