// -----------------------------------------------------------------------------
// This file contains all styles related to the table component
// -----------------------------------------------------------------------------

.editor {
  table {
    border: 0;
    border-collapse: collapse;
    width: 100%;
  }

  th {
    @include type-setting(0);

    border-bottom: 1px solid $light-grey;
    color: $african-violet;
    font-family: $font-brand;
    font-weight: $font-weight-normal;
    min-width: $space * 6;
    padding: $space-half $space-two-thirds;
    text-align: left;
    vertical-align: top;

    @include media('≥l') {
      @include type-setting(2);
    }
  }

  thead th {
    border-bottom-width: 3px;
  }

  td {
    @include type-setting(-1);

    border-bottom: 1px solid $light-grey;
    min-width: $space * 6;
    padding: $space-half $space-two-thirds;
    vertical-align: top;
  }

  tr:nth-child(even) {
    td,
    th {
      background-color: $light-grey;
    }
  }
}


// Responsive styling
.table-wrapper {
  max-width: 100%;
  overflow-x: auto;

  &.has-scroll {
    margin-bottom: $space;
    overflow: hidden;
    position: relative;

    &::after {
      border-radius: 25% 0 0 25%;
      box-shadow: -5px 0 10px rgba($black, 0.25);
      content: '';
      height: 100%;
      left: 100%;
      position: absolute;
      top: 0;
      width: 50px;
    }

    .table-scroller {
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: $space-half;
      }

      &::-webkit-scrollbar-track {
        background: $light-grey;
        border-radius: 6px;
        box-shadow: inset 0 0 2px rgba($black, 0.15);
      }

      &::-webkit-scrollbar-thumb {
        background: $african-violet;
        border-radius: 6px;
        margin: 5px;
      }
    }
  }
}
