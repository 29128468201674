// -----------------------------------------------------------------------------
// This file contains all styles related to the advanced banner component
// -----------------------------------------------------------------------------




//
// Outer wrapper
//
.advanced-banner {
  color: $white;
  display: flex;
  min-height: $advb-height-mobile-slim;
  position: relative;

  @include media ('>=m-tweak') {
    min-height: $advb-height-desktop-slim;
  }

  &:not(.advanced-banner--has-callout) {
    .advanced-banner__strapline,
    .advanced-banner__text,
    .advanced-banner__button {
      display: none;
    }
  }
}



//
// Overlays
//

/*
Required for all overlay classes.
Intentionally not nested under .advanced-banner__background-row due to specificity issues
*/
[class*="advanced-banner__background-row--overlay"]::before {
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}



//
// Background row
//
.advanced-banner__background-row {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: $space-and-half;
  position: absolute;
  right: 0;
  top: 0;

  /* In a specific case when the callout drops below the banner on mobile and this row gets position relative (not absolute),
  the min height of this row needs to match the min-height of the parent. Otherwise a gap may be left. */
  @include media ('<m-tweak') {
    min-height: $advb-height-mobile-slim;
  }

  @include media ('>=m-tweak') {
    padding: $space-and-half $space;
  }

  // Background image alignment
  &--left-top {
    background-position: left top;
  }

  &--left-middle {
    background-position: left center;
  }

  &--left-bottom {
    background-position: left bottom;
  }

  &--middle-top {
    background-position: center top;
  }

  &--middle-middle {
    background-position: center;
  }

  &--middle-bottom {
    background-position: center bottom;
  }

  &--right-top {
    background-position: right top;
  }

  &--right-middle {
    background-position: right center;
  }

  &--right-bottom {
    background-position: right bottom;
  }

  //
  // Preset background patterns/gradients
  //

  // African Violet > African Violet Light
  &--gradient-afv-afvl {
    background-color: $african-violet; // Fallback
    background-image: linear-gradient(90deg, rgba($african-violet, 1) 0%, rgba($african-violet-light, 1) 100%);
  }

  // African Violet > Mulberry
  &--gradient-afv-mulb {
    background-color: $african-violet; // Fallback
    background-image: linear-gradient(90deg, rgba($african-violet, 1) 0%, rgba($mulberry, 1) 100%);
  }

  // African Violet > Olympic Green
  &--gradient-afv-olg {
    background-color: $african-violet; // Fallback
    background-image: linear-gradient(90deg, rgba($african-violet, 1) 0%, rgba($olympic-green, 1) 100%);
  }

  // Mulberry > Mulberry Light
  &--gradient-mulb-mulbla {
    background-color: $mulberry; // Fallback
    background-image: linear-gradient(90deg, rgba($mulberry, 1) 0%, rgba($mulberry-light-aa, 1) 100%);
  }

  // Dark overlay
  &--overlay-dark {
    &::before {
      background: $advb-overlay-dark;
    }
  }

  // The 'box' overlay doesn't fill the entire width of the banner on wide viewports
  &--overlay-dark-box {
    &::before {
      background: $advb-overlay-dark;
      left: calc((100vw - $advb-container-max-width) / 2.2);
      right: calc((100vw - $advb-container-max-width) / 2.2);
    }
  }

  // Bottom-to-top
  &--overlay-dark-btt {
    &::before {
      background: linear-gradient(0deg, rgba($black, 0.75) 35%, rgba($black, 0) 110%);

      @include media ('>=m-tweak') {
        background: linear-gradient(0deg, rgba($black, 0.75) 35%, rgba($black, 0) 80%);
      }
    }
  }

  // Left-to-right
  &--overlay-dark-ltr {
    &::before {
      background: linear-gradient(90deg, rgba($black, 0.75) 35%, rgba($black, 0) 110%);

      @include media ('>=m-tweak') {
        background: linear-gradient(90deg, rgba($black, 0.75) 35%, rgba($black, 0) 80%);
      }
    }
  }

  // Top-to-bottom
  &--overlay-dark-ttb {
    &::before {
      background: linear-gradient(180deg, rgba($black, 0.75) 35%, rgba($black, 0) 110%);

      @include media ('>=m-tweak') {
        background: linear-gradient(180deg, rgba($black, 0.75) 35%, rgba($black, 0) 80%);
      }
    }
  }

  // Right-to-left
  &--overlay-dark-rtl {
    &::before {
      background: linear-gradient(270deg, rgba($black, 0.75) 35%, rgba($black, 0) 120%);

      @include media ('>=m-tweak') {
        background: linear-gradient(270deg, rgba($black, 0.75) 35%, rgba($black, 0) 80%);
      }
    }
  }

  // Light overlay
  &--overlay-light {
    &::before {
      background: $advb-overlay-light;
    }
  }

  // The 'box' overlay doesn't fill the entire width of the banner on wide viewports
  &--overlay-light-box {
    &::before {
      background: $advb-overlay-light;
      left: calc((100vw - $advb-container-max-width) / 2.2);
      right: calc((100vw - $advb-container-max-width) / 2.2);
    }
  }

  // Bottom-to-top
  &--overlay-light-btt {
    &::before {
      background: linear-gradient(0deg, rgba($white, 0.9) 35%, rgba($white, 0) 110%);

      @include media ('>=m-tweak') {
        background: linear-gradient(0deg, rgba($white, 0.9) 35%, rgba($white, 0) 80%);
      }
    }
  }

  // Left-to-right
  &--overlay-light-ltr {
    &::before {
      background: linear-gradient(90deg, rgba($white, 0.9) 35%, rgba($white, 0) 110%);

      @include media ('>=m-tweak') {
        background: linear-gradient(90deg, rgba($white, 0.9) 35%, rgba($white, 0) 80%);
      }
    }
  }

  // Top-to-bottom
  &--overlay-light-ttb {
    &::before {
      background: linear-gradient(180deg, rgba($white, 0.9) 35%, rgba($white, 0) 110%);

      @include media ('>=m-tweak') {
        background: linear-gradient(180deg, rgba($white, 0.9) 35%, rgba($white, 0) 80%);
      }
    }
  }

  // Right-to-left
  &--overlay-light-rtl {
    &::before {
      background: linear-gradient(270deg, rgba($white, 0.9) 35%, rgba($white, 0) 110%);

      @include media ('>=m-tweak') {
        background: linear-gradient(270deg, rgba($white, 0.9) 35%, rgba($white, 0) 80%);
      }
    }
  }

  // Remove overlay on mobile
  &--overlay-remove-mobile {
    &::before {
      @include media ('<m-tweak') {
        background: none;
      }
    }
  }
}



//
// Foreground image container
//
.advanced-banner__fg-image-container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: $advb-container-max-width;
  width: 100%;
  z-index: 1;

  @include media ('>=m-tweak') {
    &--left-top {
      align-items: flex-start; // Vertical
      justify-content: flex-start; // Horizontal
    }

    &--left-middle {
      justify-content: flex-start;
    }

    &--left-bottom {
      align-items: flex-end;
      justify-content: flex-start;
    }

    &--middle-top {
      align-items: flex-start;
      justify-content: center;
    }

    &--middle-middle {
      justify-content: center;
    }

    &--middle-bottom {
      align-items: flex-end;
      justify-content: center;
    }

    &--right-top {
      align-items: flex-start;
      justify-content: flex-end;
    }

    &--right-middle {
      justify-content: flex-end;
    }

    &--right-bottom {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}



//
// Foreground <figure>
//
.advanced-banner__figure {
  margin: 0;
  text-align: center;

  @include media ('>=m-tweak') {
    max-width: 35%;
  }
}



//
// Foreground image
//
.advanced-banner__fg-image {
  max-height: 250px;
  width: auto;
}



//
// Foreground image caption
//
.advanced-banner__caption {
  @include type-setting(2);

  font-family: $font-brand-bold;
  margin-top: $space;

  // Hide if the caption is empty
  &:empty {
    display: none;
  }
}



//
// Callout row
//
.advanced-banner__callout-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: $space;
  width: 100%;
  z-index: 1;

  @include media ('>=m-tweak') {
    padding: $space-and-half $space;
  }
}



//
// Callout container
//
.advanced-banner__callout-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  max-width: $advb-container-max-width;
  width: 100%;

  &--left-top {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &--left-middle {
    align-items: center;
    justify-content: flex-start;
  }

  &--left-bottom {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &--middle-top {
    align-items: flex-start;
    justify-content: center;
    text-align: center;

    .advanced-banner__callout {
      align-items: center;
    }
  }

  &--middle-middle {
    align-items: center;
    justify-content: center;
    text-align: center;

    .advanced-banner__callout {
      align-items: center;
    }
  }

  &--middle-bottom {
    align-items: flex-end;
    justify-content: center;
    text-align: center;

    .advanced-banner__callout {
      align-items: center;
    }
  }

  &--right-top {
    align-items: flex-start;
    justify-content: flex-end;
    text-align: right;

    .advanced-banner__callout {
      align-items: flex-end;
    }
  }

  &--right-middle {
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    .advanced-banner__callout {
      align-items: flex-end;
    }
  }

  &--right-bottom {
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;

    .advanced-banner__callout {
      align-items: flex-end;
    }
  }
}



//
// Callout
//
.advanced-banner__callout {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: $space $space $space-half $space;
  position: relative;

  @include media ('>=m-tweak') {
    max-width: 60%;

    // Chunky callout
    &--chunky {
      padding: $space-and-two-thirds $space-double $space-and-half $space-double;
    }
  }

  // Give child items a position property so they participate in z-index stacking
  & > * {
    position: relative;
  }

  // Use a before pseudo element to set background colour so that opacity can be changed without affecting child items
  &::before {
    background-color: $african-violet;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  // Reduced opacity options
  &--opacity90 {
    &::before {
      opacity: 0.9;
    }
  }

  &--opacity80 {
    &::before {
      opacity: 0.8;
    }
  }

  &--opacity70 {
    &::before {
      opacity: 0.7;
    }
  }

  &--opacity60 {
    &::before {
      opacity: 0.6;
    }
  }

  &--opacity50 {
    &::before {
      opacity: 0.5;
    }
  }

  &--opacity40 {
    &::before {
      opacity: 0.4;
    }
  }

  &--opacity30 {
    &::before {
      opacity: 0.3;
    }
  }

  &--opacity20 {
    &::before {
      opacity: 0.2;
    }
  }

  &--opacity10 {
    &::before {
      opacity: 0.1;
    }
  }
}



//
// Callout transparency
//

div.advanced-banner {
  // Should NOT affect callouts that appear below banner on mobile
  @include media ('<m-tweak') {
    &:not(.advanced-banner--has-fg-image, .advanced-banner--drop-callout) {
      .advanced-banner__callout--transparent {
        padding: 0;

        &::before {
          background-color: transparent;
        }
      }
    }
  }

  // Desktop only
  @include media ('>=m-tweak') {
    .advanced-banner__callout--transparent {
      padding: 0;

      &::before {
        background-color: transparent;
      }
    }
  }
}



//
// Heading
//
.advanced-banner__heading {
  @include type-setting(9);

  font-family: $font-brand-bold;
  margin-bottom: $space-half;

  &--bigger {
    @include media ('>=m-tweak') {
      @include type-setting(11);
    }
  }

  &--biggest {
    @include media ('>=m-tweak') {
      @include type-setting(13);
    }
  }
}



//
// Sub-heading
//
.advanced-banner__strapline {
  @include type-setting(3);

  font-family: $font-brand-bold;
  margin-bottom: $space-half;

  &--bigger {
    @include media ('>=m-tweak') {
      @include type-setting(5);
    }
  }

  &--biggest {
    @include media ('>=m-tweak') {
      @include type-setting(7);
    }
  }

  // Hide if empty
  &:empty {
    display: none;
  }
}



//
// Paragraph text
//
.advanced-banner__text {
  margin-bottom: $space-half;

  @include media ('>=m-tweak') {
    max-width: 600px;
  }

  &--bigger {
    @include media ('>=m-tweak') {
      @include type-setting(1);
    }
  }

  &--biggest {
    @include media ('>=m-tweak') {
      @include type-setting(2);
    }
  }

  // Hide if empty
  &:empty {
    display: none;
  }
}



//
// Button
//
.advanced-banner__button {
  background-color: $mulberry;
  margin-bottom: $space-half;
  margin-top: $space-third;

  // Make sure buttons don't display if they are not in a callout
  // &:not(.advanced-banner__callout .advanced-banner__button) {
  //   display: none;
  // }

  svg.icon__svg {
    transform: translateX(5px);
  }

  // Defualt styles (for dark background colours)
  &:focus,
  &:hover {
    background-color: $mulberry-light;
    color: $white;

    svg.icon__svg {
      fill: $white;
      transform: translateX(5px);
    }
  }

  // Styles for lighter background colours
  &--white,
  &--canary,
  &--cedar,
  &--cedar-light,
  &--dove-grey,
  &--light-grey {
    color: $dark-grey;

    svg.icon__svg {
      fill: $dark-grey;
    }

    &:focus,
    &:hover {
      svg.icon__svg {
        fill: $white;
      }
    }
  }
}





//
// Medium banner settings
//
.advanced-banner--medium {
  min-height: $advb-height-mobile-medium;

  @include media ('>=m-tweak') {
    min-height: $advb-height-desktop-medium;
  }

  .advanced-banner__background-row {
    /* In a specific case when the callout drops below the banner on mobile and this row gets position relative (not absolute),
    the min height of this row needs to match the min-height of the parent. Otherwise a gap may be left. */
    @include media ('<m-tweak') {
      min-height: $advb-height-mobile-medium;
    }
  }

  .advanced-banner__fg-image {
    max-height: 300px;
  }
}



//
// Tall banner settings
//
.advanced-banner--tall {
  min-height: $advb-height-mobile-tall;

  @include media ('>=m-tweak') {
    min-height: $advb-height-desktop-tall;
  }

  .advanced-banner__background-row {
    /* In a specific case when the callout drops below the banner on mobile and this row gets position relative (not absolute),
    the min height of this row needs to match the min-height of the parent. Otherwise a gap may be left. */
    @include media ('<m-tweak') {
      min-height: $advb-height-mobile-tall;
    }
  }

  .advanced-banner__fg-image {
    max-height: 350px;
  }
}



//
// When foreground image is in the middle (horizontally)
//
.advanced-banner__fg-image-container--middle-top,
.advanced-banner__fg-image-container--middle-middle,
.advanced-banner__fg-image-container--middle-bottom {
  .advanced-banner__figure {
    @include media ('>=m-tweak') {
      max-width: 60%;
    }
  }
}



//
// Position callout below banner
//
.advanced-banner--has-fg-image,
.advanced-banner--drop-callout {
  @include media ('<m-tweak') {
    display: block;

    .advanced-banner__background-row {
      position: relative;
    }

    .advanced-banner__callout-row {
      padding: 0;
    }

    .advanced-banner__callout-container {
      display: block;
      padding: 0;
      transform: translateY(0);
    }

    .advanced-banner__callout {
      align-items: flex-start;
      text-align: left;

      // Ensures opacity is not reduced - if it is reduced on desktop
      &::before {
        opacity: 1;
      }
    }
  }
}



//
// Colours values
//

/* Primarily used for text */

.advanced-banner__heading,
.advanced-banner__strapline,
.advanced-banner__text {
  &--african-violet {
    color: $african-violet;
  }

  &--african-violet-light {
    color: $african-violet-light;
  }

  &--asphalt {
    color: $asphalt;
  }

  &--black {
    color: $black;
  }

  &--bronze {
    color: $bronze;
  }

  &--bronze-aa {
    color: $bronze-aa;
  }

  &--bronze-dark {
    color: $bronze-dark;
  }

  &--canary {
    color: $canary;
  }

  &--cedar {
    color: $cedar;
  }

  &--cedar-aa {
    color: $cedar-aa;
  }

  &--cedar-light {
    color: $cedar-light;
  }

  &--dark-grey {
    color: $dark-grey;
  }

  &--dove-grey {
    color: $dove-grey;
  }

  &--fountain-grey {
    color: $fountain-grey;
  }

  &--golden {
    color: $gold;
  }

  &--golden-aa {
    color: $gold-aa;
  }

  &--golden-light {
    color: $gold-light;
  }

  &--light-grey {
    color: $light-grey;
  }

  &--mercia-red {
    color: $mercia-red;
  }

  &--mercia-red-light {
    color: $mercia-red-light;
  }

  &--mercia-red-light-aa {
    color: $mercia-red-light-aa;
  }

  &--mulberry {
    color: $mulberry;
  }

  &--mulberry-light {
    color: $mulberry-light;
  }

  &--mulberry-light-aa {
    color: $mulberry-light-aa;
  }

  &--neptune {
    color: $neptune;
  }

  &--neptune-light {
    color: $neptune-light;
  }

  &--neptune-light-aa {
    color: $neptune-light-aa;
  }

  &--olympic-green {
    color: $olympic-green;
  }

  &--olympic-green-aa {
    color: $olympic-green-aa;
  }

  &--olympic-green-dark {
    color: $olympic-green-dark;
  }

  &--peacock {
    color: $peacock;
  }

  &--peacock-aa {
    color: $peacock-aa;
  }

  &--peacock-light {
    color: $peacock-light;
  }

  &--pearl-white {
    color: $pearl-white;
  }

  &--pebble {
    color: $pebble;
  }

  &--petrol-blue {
    color: $petrol-blue;
  }

  &--petrol-blue-light {
    color: $petrol-blue-light;
  }

  &--petrol-blue-light-aa {
    color: $petrol-blue-light-aa;
  }

  &--silver {
    color: $silver;
  }

  &--silver-aa {
    color: $silver-aa;
  }

  &--white {
    color: $white;
  }
}



//
// Background colours
//

/* The background row and buttons only */
.advanced-banner__background-row,
.advanced-banner__button {
  &--african-violet {
    background-color: $african-violet;
  }

  &--african-violet-light {
    background-color: $african-violet-light;
  }

  &--asphalt {
    background-color: $asphalt;
  }

  &--black {
    background-color: $black;
  }

  &--bronze {
    background-color: $bronze;
  }

  &--bronze-aa {
    background-color: $bronze-aa;
  }

  &--bronze-dark {
    background-color: $bronze-dark;
  }

  &--canary {
    background-color: $canary;
  }

  &--cedar {
    background-color: $cedar;
  }

  &--cedar-aa {
    background-color: $cedar-aa;
  }

  &--cedar-light {
    background-color: $cedar-light;
  }

  &--dark-grey {
    background-color: $dark-grey;
  }

  &--dove-grey {
    background-color: $dove-grey;
  }

  &--fountain-grey {
    background-color: $fountain-grey;
  }

  &--golden {
    background-color: $gold;
  }

  &--golden-aa {
    background-color: $gold-aa;
  }

  &--golden-light {
    background-color: $gold-light;
  }

  &--light-grey {
    background-color: $light-grey;
  }

  &--mercia-red {
    background-color: $mercia-red;
  }

  &--mercia-red-light {
    background-color: $mercia-red-light;
  }

  &--mercia-red-light-aa {
    background-color: $mercia-red-light-aa;
  }

  &--mulberry {
    background-color: $mulberry;
  }

  &--mulberry-light {
    background-color: $mulberry-light;
  }

  &--mulberry-light-aa {
    background-color: $mulberry-light-aa;
  }

  &--neptune {
    background-color: $neptune;
  }

  &--neptune-light {
    background-color: $neptune-light;
  }

  &--neptune-light-aa {
    background-color: $neptune-light-aa;
  }

  &--olympic-green {
    background-color: $olympic-green;
  }

  &--olympic-green-aa {
    background-color: $olympic-green-aa;
  }

  &--olympic-green-dark {
    background-color: $olympic-green-dark;
  }

  &--peacock {
    background-color: $peacock;
  }

  &--peacock-aa {
    background-color: $peacock-aa;
  }

  &--peacock-light {
    background-color: $peacock-light;
  }

  &--pearl-white {
    background-color: $pearl-white;
  }

  &--pebble {
    background-color: $pebble;
  }

  &--petrol-blue {
    background-color: $petrol-blue;
  }

  &--petrol-blue-light {
    background-color: $petrol-blue-light;
  }

  &--petrol-blue-light-aa {
    background-color: $petrol-blue-light-aa;
  }

  &--silver {
    background-color: $silver;
  }

  &--silver-aa {
    background-color: $silver-aa;
  }

  &--white {
    background-color: $white;
  }
}

/*
Callout bg colours only.
before:: pseudo elements are used to set the bg colour for callouts so that the opacity of the pseudo element can be reduced by X amount (via the CMS) to create a translucent effect.
*/

div.advanced-banner__callout {
  &--african-violet {
    &::before {
      background-color: $african-violet;
    }
  }

  &--african-violet-light {
    &::before {
      background-color: $african-violet-light;
    }
  }

  &--asphalt {
    &::before {
      background-color: $asphalt;
    }
  }

  &--black {
    &::before {
      background-color: $black;
    }
  }

  &--bronze {
    &::before {
      background-color: $bronze;
    }
  }

  &--bronze-aa {
    &::before {
      background-color: $bronze-aa;
    }
  }

  &--bronze-dark {
    &::before {
      background-color: $bronze-dark;
    }
  }

  &--canary {
    &::before {
      background-color: $canary;
    }
  }

  &--cedar {
    &::before {
      background-color: $cedar;
    }
  }

  &--cedar-aa {
    &::before {
      background-color: $cedar-aa;
    }
  }

  &--cedar-light {
    &::before {
      background-color: $cedar-light;
    }
  }

  &--dark-grey {
    &::before {
      background-color: $dark-grey;
    }
  }

  &--dove-grey {
    &::before {
      background-color: $dove-grey;
    }
  }

  &--fountain-grey {
    &::before {
      background-color: $fountain-grey;
    }
  }

  &--golden {
    &::before {
      background-color: $gold;
    }
  }

  &--golden-aa {
    &::before {
      background-color: $gold-aa;
    }
  }

  &--golden-light {
    &::before {
      background-color: $gold-light;
    }
  }

  &--light-grey {
    &::before {
      background-color: $light-grey;
    }
  }

  &--mercia-red {
    &::before {
      background-color: $mercia-red;
    }
  }

  &--mercia-red-light {
    &::before {
      background-color: $mercia-red-light;
    }
  }

  &--mercia-red-light-aa {
    &::before {
      background-color: $mercia-red-light-aa;
    }
  }

  &--mulberry {
    &::before {
      background-color: $mulberry;
    }
  }

  &--mulberry-light {
    &::before {
      background-color: $mulberry-light;
    }
  }

  &--mulberry-light-aa {
    &::before {
      background-color: $mulberry-light-aa;
    }
  }

  &--neptune {
    &::before {
      background-color: $neptune;
    }
  }

  &--neptune-light {
    &::before {
      background-color: $neptune-light;
    }
  }

  &--neptune-light-aa {
    &::before {
      background-color: $neptune-light-aa;
    }
  }

  &--olympic-green {
    &::before {
      background-color: $olympic-green;
    }
  }

  &--olympic-green-aa {
    &::before {
      background-color: $olympic-green-aa;
    }
  }

  &--olympic-green-dark {
    &::before {
      background-color: $olympic-green-dark;
    }
  }

  &--peacock {
    &::before {
      background-color: $peacock;
    }
  }

  &--peacock-aa {
    &::before {
      background-color: $peacock-aa;
    }
  }

  &--peacock-light {
    &::before {
      background-color: $peacock-light;
    }
  }

  &--pearl-white {
    &::before {
      background-color: $pearl-white;
    }
  }

  &--pebble {
    &::before {
      background-color: $pebble;
    }
  }

  &--petrol-blue {
    &::before {
      background-color: $petrol-blue;
    }
  }

  &--petrol-blue-light {
    &::before {
      background-color: $petrol-blue-light;
    }
  }

  &--petrol-blue-light-aa {
    &::before {
      background-color: $petrol-blue-light-aa;
    }
  }

  &--silver {
    &::before {
      background-color: $silver;
    }
  }

  &--silver-aa {
    &::before {
      background-color: $silver-aa;
    }
  }

  &--white {
    &::before {
      background-color: $white;
    }
  }
}



//
// Fonts
//
.advanced-banner__heading,
.advanced-banner__strapline,
.advanced-banner__text,
.advanced-banner__button {
  // DIN
  &--font-brand {
    font-family: $font-brand;
  }

  &--font-brand-light {
    font-family: $font-brand-light;
  }

  &--font-brand-medium {
    font-family: $font-brand-medium;
  }

  &--font-brand-bold {
    font-family: $font-brand-bold;
  }

  &--font-brand-black {
    font-family: $font-brand-black;
  }

  // DIN Round
  &--font-brand-round {
    font-family: $font-brand-round;
  }

  &--font-brand-round-light {
    font-family: $font-brand-round-light;
  }

  &--font-brand-round-medium {
    font-family: $font-brand-round-medium;
  }

  &--font-brand-round-bold {
    font-family: $font-brand-round-bold;
  }

  &--font-brand-round-black {
    font-family: $font-brand-round-black;
  }

  // DIN Condensed
  &--font-brand-condensed {
    font-family: $font-brand-condensed;
  }

  &--font-brand-condensed-light {
    font-family: $font-brand-condensed-light;
  }

  &--font-brand-condensed-medium {
    font-family: $font-brand-condensed-medium;
  }

  &--font-brand-condensed-bold {
    font-family: $font-brand-condensed-bold;
  }

  &--font-brand-condensed-black {
    font-family: $font-brand-condensed-black;
  }
}
