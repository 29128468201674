// -----------------------------------------------------------------------------
// This file contains all styles related to the news component.
// -----------------------------------------------------------------------------

.news-listing {
  // Heading
  .heading {
    margin-bottom: $space;
    margin-top: 0;
  }



  // Link wrapper
  .link-wrapper {
    border-top: 1px solid $fountain-grey;
    padding-top: $space-three-quarters;
  }



  // In wide page layouts
  .page-layout-wide &,
  .page-layout-wide-boxed & {
    // Heading and link wrapper
    .heading,
    .link-wrapper {
      text-align: center;
    }



    // Link wrapper
    .link-wrapper {
      @include media('≥s') {
        margin-left: $gutter-half;
        margin-right: $gutter-half;
      }
    }
  }
}
