// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//
// Remove the bottom margin of a module's last child
//
%module {
  > *:last-child,
  > *:last-child > *:last-child,
  > *:last-child > *:last-child > *:last-child,
  > *:last-child > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
  }
}





//
// Hide element while making it readable for screen readers
//
%visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Hide element while making it readable for screen readers - MOBILE VERSION ONLY
%visually-hidden-mobile {
  @include media('≤l') {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}





//
// Page heading
//
%page-heading {
  @include type-setting(9);
}





//
// Heading
//
%heading {
  @include type-setting(7);

  margin-top: $space-double;
}





//
// Subheading
//
%subheading {
  @include type-setting(4);

  margin-top: $space-double;


  // Remove space above if directly below a heading
  .heading + & {
    margin-top: 0;
  }
}





//
// Sub-subheading
//
%sub-subheading {
  @include type-setting(3);

  margin-top: $space-double;


  // Remove space above if directly below a subheading
  .subheading + & {
    margin-top: 0;
  }
}





//
// Lead paragraph
//
%lead-paragraph {
  @include type-setting(2);

  font-family: $font-brand;
}





//
// Make items go full width
//
%full-width {
  left: 50%;
  margin-left: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
}





//
// Overlay
//
%overlay {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}





//
// Sidebar navigation active chevron
//
%sidebar-nav-chevron {
  &::before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: inline-block;
    height: 8px;
    left: -($space - $space-third);
    margin-top: -4px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    vertical-align: top;
    width: 8px;

    @include media('≥m') {
      height: 12px;
      left: -$space;
      margin-top: -6px;
      width: 12px;
    }
  }
}
