// -----------------------------------------------------------------------------
// This file contains all styles related to the popup component.
// -----------------------------------------------------------------------------

.mfp-iframe-holder {
  .mfp-content {
    max-height: 90%;
    max-width: 1600px;
  }
}
