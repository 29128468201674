// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page news.
// -----------------------------------------------------------------------------

.home-news-events {
  //
  // News outer container
  //
  .home-news {
    @include media('≥s') {
      padding-bottom: $space-treble;

      .no-events & {
        padding-bottom: $space;
      }
    }
  }





  //
  // Social
  //
  .home-news-events__social {
    margin-left: auto;
    text-align: right;

    @include media('≤xs-tweak') {
      margin-top: $space-half;
    }

    .home-social__heading {
      margin-bottom: $space-eighth;
    }

    .home-social__links {
      justify-content: flex-end;
    }

    .home-social__svg {
      fill: $mulberry;
      transition: fill 0.3s;
    }

    .home-social__link {
      padding: 0 0 0 $space-half;

      &:hover,
      &:focus {
        .home-social__svg {
          fill: $african-violet-light;
        }
      }
    }
  }





  //
  // News body
  //
  .home-news__body {
    margin: 0;

    @include media('≥s') {
      display: flex;
      flex-flow: row wrap;
    }

    @include media('≥s-tweak') {
      margin: 0 (-$space-quarter);
    }

    .card--news-article {
      position: relative;

      @include media('≥s') {
        padding-right: $space-double;
        width: percentage(math.div(7, 12));
      }

      .card__link {
        .card__text--date {
          @include type-setting(-1);

          bottom: $space-quarter;
          color: $text-color;
          position: absolute;
          right: $space;
        }
      }
    }

    .card__content {
      @include media('≥m') {
        bottom: -$space-and-half;
        left: -$space;
        position: absolute;
        width: percentage(math.div(12, 12));
      }
    }

    .card__heading-container {
      background-color: $white;
      padding: $space-and-half;

      @include media('≤m') {
        bottom: $space + $space-third;
        display: block;
        left: -$space-two-thirds;
        padding: $space;
        position: relative;
        width: 100%;
      }

      @include media('≥m') {
        bottom: 0;
        left: 0;
        position: absolute;
        width: percentage(math.div(9, 12));
      }

      // Arrow
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.954 7.83c.005-.01.013-.016.017-.025a.33.33 0 0 0 0-.268c0-.01-.01-.016-.013-.026a.302.302 0 0 0-.062-.08l-.005-.01L7.552.09a.333.333 0 1 0-.44.5l7.67 6.75H.332a.333.333 0 0 0 0 .667h14.45l-7.67 6.75a.332.332 0 0 0 .22.583.33.33 0 0 0 .22-.083l8.335-7.333c.002 0 .003-.005.005-.007a.348.348 0 0 0 .062-.083z' fill='%23361163'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        bottom: 0;
        content: '';
        display: block;
        height: 30px;
        position: absolute;
        right: 0;
        transition: all ease 0.3s;
        width: 30px;

        @include media ('>=s-tweak') {
          height: 52px;
          width: 52px;
        }
      }

      .card__heading {
        @include type-setting(4);

        margin-bottom: 0;
      }
    }

    .list--news-listing {
      .news-link {
        display: block;
        margin-bottom: 0;
        padding: $space-and-quarter 0;
        position: relative;
        width: 100%;

        &:last-of-type {
          margin-bottom: $space;
        }

        &:first-of-type {
          border-bottom: $asphalt 1px solid;
        }

        // Arrow
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.954 7.83c.005-.01.013-.016.017-.025a.33.33 0 0 0 0-.268c0-.01-.01-.016-.013-.026a.302.302 0 0 0-.062-.08l-.005-.01L7.552.09a.333.333 0 1 0-.44.5l7.67 6.75H.332a.333.333 0 0 0 0 .667h14.45l-7.67 6.75a.332.332 0 0 0 .22.583.33.33 0 0 0 .22-.083l8.335-7.333c.002 0 .003-.005.005-.007a.348.348 0 0 0 .062-.083z' fill='%23525e66'/%3E%3C/svg%3E%0A");
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto;
          bottom: 0;
          content: '';
          display: block;
          height: 30px;
          position: absolute;
          right: 0;
          transition: all ease 0.3s;
          width: 30px;

          @include media ('>=s-tweak') {
            height: 52px;
            width: 52px;
          }
        }

        .news-link__heading {
          @include type-setting(3);

          font-family: $font-brand-medium;
        }

        .news-link__date {
          @include type-setting(-1);

          color: $text-color;
        }
      }
    }
  }

  .home-news__listing-button-container {
    @include media('≥s') {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: percentage(math.div(5, 12));
    }
  }





  //
  // Discover more 'News' or 'Events' button
  //
  .home-events__button {
    float: right;
  }
}
