// -----------------------------------------------------------------------------
// This file contains all styles related to the logo component.
// -----------------------------------------------------------------------------

//
// Outer container
//
.logo {
  // Always centre logos in sidebar
  .sidebar__section & {
    text-align: center;
  }
}





//
// Link
//
.logo__link {
  display: block;
}
