// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

// Main button styles
.button {
  @include type-setting(2);

  background-color: $mulberry;
  border: 0;
  border-radius: 0;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-brand;
  padding: $space-third $space-three-quarters $space-half;
  position: relative;
  transition: 0.3s;

  @include media('≥l') {
    padding-bottom: $space-two-thirds;
    padding-top: $space-half;
  }

  &:hover,
  &:focus {
    background-color: $african-violet;
    color: $white;
  }

  .icon__svg {
    fill: $white;
    transition: fill 0.3s;
  }
}





// Basic button
.button--basic {
  background-color: transparent;
  padding: 0;

  &:hover,
  &:focus {
    background-color: transparent;
  }
}





// Small button
.button--small {
  @include type-setting(1);

  padding: $space-quarter $space-half;

  @include media('≥l') {
    padding-bottom: $space-quarter + $space-twelth;
  }
}





// Large button
.button--large {
  @include type-setting(3);

  @include media('≥l') {
    padding: $space-two-thirds $space;
  }
}





// Default button
.button--default {
  .icon__svg,
  .icon svg {
    fill: $white;
  }
}





// Light button
.button--light {
  background-color: $white;
  color: $mulberry;

  .icon__svg,
  .icon svg {
    fill: $mulberry;
  }

  &:hover,
  &:focus {
    background-color: $white;
    color: $african-violet;

    .icon__svg,
    .icon svg {
      fill: $african-violet;
    }
  }
}





// Standout button
.button--standout {
  background-color: $african-violet;

  .icon__svg,
  .icon svg {
    fill: $white;
  }

  &:hover,
  &:focus {
    background-color: $mulberry;
  }
}





// Subtle button
.button--subtle {
  background-color: transparent;
  border: 1px solid $mulberry;
  color: $mulberry;

  .icon__svg,
  .icon svg {
    fill: $mulberry;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $african-violet;
    color: $african-violet;

    .icon__svg,
    .icon svg {
      fill: $african-violet;
    }
  }
}





// Button with icon
.button--icon-left,
.button--icon-right {
  .icon {
    margin-top: -$space-third;
    position: absolute;
    top: 50%;
  }
}

.button--icon-right {
  padding-right: $space-and-quarter + $space-two-thirds;

  .icon {
    right: $space-three-quarters;
  }

  .icon--ui-external {
    margin-top: -($space-third + $space-twelth);
  }
}

.button--icon-left {
  padding-left: $space-and-quarter + $space-two-thirds;

  .icon {
    left: $space-three-quarters;
  }
}





// Contact button
.button--contact {
  @include type-setting(0);

  background-color: transparent;
  color: $mulberry;
  font-family: $font-regular;
  margin-bottom: $space-half;
  margin-right: $space-half;
  padding: 0;



  // Icon
  .button__icon-wrapper {
    background-color: $mulberry;
    display: inline-block;
    height: $space-and-half;
    left: 0;
    padding-left: $space-third;
    padding-right: $space-third;
    position: absolute;
    top: 0;
    transition: background-color 0.3s;
    vertical-align: top;
    width: $space-and-half;

    @include media('≥wide') {
      height: $space-and-two-thirds;
      width: $space-and-two-thirds;
    }
  }

  .icon {
    height: 100%;
    width: 100%;
  }

  .icon__svg {
    fill: $white;
  }



  // Text
  .button__text {
    background-color: $white;
    border: 1px solid $fountain-grey;
    display: inline-block;
    min-height: $space-and-half;
    padding: $space-quarter $space-half $space-quarter $space-double;
    transition: border-color 0.3s;
    vertical-align: top;

    @include media('≥wide') {
      min-height: $space-and-two-thirds;
      padding-left: $space-double + $space-quarter;
      padding-right: $space-two-thirds;
    }
  }



  // Hover and focus
  &:hover,
  &:focus {
    background-color: transparent;
    color: $african-violet;



    // Icon
    .button__icon-wrapper {
      background-color: $african-violet;
    }



    // Text
    .button__text {
      border-color: $african-violet;
    }
  }
}





// Content type container
.content-type--button {
  &.content-type--inline {
    margin-bottom: $space-half;
    margin-right: $space-half;
  }
}




// Add to calendar button.
// We cannot edit the classes used in the button so we need to override stylelint.
.atcb-button-wrapper {
  padding: 0;
}


.atcb-button {
  // Main button styles
  @include type-setting(2);

  background-color: $mulberry !important; /* stylelint-disable-line declaration-no-important */
  border: 0;
  border-radius: 0;
  box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
  color: $white;
  cursor: pointer;
  font-family: $font-brand;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding: $space-third $space-three-quarters !important; /* stylelint-disable-line declaration-no-important */
  //padding-left: $space-and-quarter + $space-two-thirds;
  position: relative;
  //transition: 0.3s;

  @include media('≥l') {
    padding-bottom: $space-two-thirds !important; /* stylelint-disable-line declaration-no-important */
    padding-top: $space-half !important; /* stylelint-disable-line declaration-no-important */
  }

  .atcb-icon svg,
  .atcb-icon-trigger {
    fill: $white !important; /* stylelint-disable-line declaration-no-important */
    transition: fill 0.3s;
  }

  &:hover,
  &:focus {
    background-color: $african-violet !important; /* stylelint-disable-line declaration-no-important */
    color: $white;

    .atcb-icon svg,
    .atcb-icon-trigger {
      fill: $white !important; /* stylelint-disable-line declaration-no-important */
      transition: fill 0.3s;
    }
  }

  .atcb-button--basic & {
    // Basic button styles
    background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    padding: 0;

    &:hover,
    &:focus {
      background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .atcb-button--small & {
    // Small button styles
    @include type-setting(1);

    padding: $space-quarter $space-half !important; /* stylelint-disable-line declaration-no-important */

    @include media('≥l') {
      padding-bottom: $space-quarter + $space-twelth !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .atcb-button--large & {
    // Large button styles
    @include type-setting(3);

    padding: $space-third $space-three-quarters $space-half !important; /* stylelint-disable-line declaration-no-important */

    @include media('≥l') {
      padding: $space-two-thirds $space !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .atcb-button--default &,
  .atcb-button--standout & {
    .atcb-icon svg,
    .atcb-icon-trigger {
      fill: $white !important; /* stylelint-disable-line declaration-no-important */
      transition: fill 0.3s;
    }
  }

  .atcb-button--light & {
    // Light button styles
    background-color: $white !important; /* stylelint-disable-line declaration-no-important */
    color: $mulberry;

    .atcb-icon svg,
    .atcb-icon-trigger {
      fill: $mulberry !important; /* stylelint-disable-line declaration-no-important */
    }

    &:hover,
    &:focus {
      background-color: $white !important; /* stylelint-disable-line declaration-no-important */
      color: $african-violet;

      .atcb-icon svg,
      .atcb-icon-trigger {
        fill: $african-violet !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  .atcb-button--standout & {
    // Standout button styles
    background-color: $african-violet !important; /* stylelint-disable-line declaration-no-important */

    .atcb-icon svg,
    .atcb-icon-trigger {
      fill: $white !important; /* stylelint-disable-line declaration-no-important */
    }

    &:hover,
    &:focus {
      background-color: $mulberry !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .atcb-button--subtle & {
    // Subtle button styles
    background-color: $white !important; /* stylelint-disable-line declaration-no-important */
    border: 1px solid $mulberry;
    color: $mulberry;

    .atcb-icon svg,
    .atcb-icon-trigger {
      fill: $mulberry !important; /* stylelint-disable-line declaration-no-important */
    }

    &:hover,
    &:focus {
      background-color: $white !important; /* stylelint-disable-line declaration-no-important */
      border-color: $african-violet;
      color: $african-violet;

      .atcb-icon svg,
      .atcb-icon-trigger {
        fill: $african-violet !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}
