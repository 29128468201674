.cards-carousel {
  $arrow-offset: -$space-treble;

  @include media ('>=s-tweak') {
    padding-left: $space-five-sixths;
    padding-right: $space-five-sixths;
  }

  .cards {
    @extend %container;

    padding: 0;
  }

  .slick-slider {
    margin-bottom: $space * 4;
    position: relative;

    @include media ('>=m-tweak') {
      margin-bottom: $space * 5;
    }

    @include media ('>=wide') {
      margin-bottom: $space * 6;
    }

    .slick-track {
      align-items: stretch;
      display: flex !important; /* stylelint-disable-line declaration-no-important */
      flex-direction: row;

      @include media ('<s-tweak') {
        margin-top: $space;
      }

      @include media ('>=s-tweak') {
        padding-top: $space-treble;
      }

      @include media ('>=m-tweak') {
        padding-top: $space-quadruple;
      }

      @include media ('>=wide') {
        padding-top: $space * 5;
      }
    }

    .slick-list {
      width: 100%;
    }
  }

  .slick-slide {
    align-items: stretch;
    display: flex !important; /* stylelint-disable-line declaration-no-important */
    flex-direction: column;
    height: auto;
    padding: 0 $space-five-sixths;

    @include media('≥s') {
      padding-left: 0;
      padding-right: 0;
    }

    > div {
      align-items: stretch;
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
    }

    .card__container,
    .card__content {
      height: 100%;
    }

    &:not(.slick-active) {
      pointer-events: none;
    }
  }

  .slick-arrow {
    background-color: rgba($white, 0.7);
    background-image: encode-svg('<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.97949 26L13.9795 14L1.97949 2" stroke="#{$african-violet}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-position: calc(50% + 1px) 50%;
    background-repeat: no-repeat;
    background-size: 7px 14px;
    border-radius: 100%;
    cursor: pointer;
    display: none !important; /* stylelint-disable-line declaration-no-important */
    height: $space;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-align: center;
    text-indent: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    white-space: nowrap;
    width: $space;
    z-index: 2;

    @include media ('<s-tweak') {
      top: calc((100vw - 90px) / 2);
    }

    @include media ('>=m') {
      background-size: 12px 24px;
      height: $space-double;
      width: $space-double;
    }

    @include media ('>=wider') {
      display: block !important; /* stylelint-disable-line declaration-no-important */
    }

    // Previous slide arrow
    &.slick-prev {
      left: $arrow-offset;
      transform: translateY(-50%) rotate(180deg);

      @include media ('<s-tweak') {
        left: $space;
      }
    }

    // Next slide arrow
    &.slick-next {
      right: $arrow-offset;

      @include media ('<s-tweak') {
        right: $space;
      }
    }


    .icon {
      height: $space-double;
      width: $space;
    }

    .icon__svg {
      fill: $african-violet;
    }

    &:hover,
    &:focus {
      background-color: $african-violet-light;
      background-image: encode-svg('<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.97949 26L13.9795 14L1.97949 2" stroke="#{$white}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      border-color: transparent;
      transform: translateY(-50%) scale(1.15);

      &.slick-prev {
        transform: translateY(-50%) rotate(180deg) scale(1.15);
      }
    }
  }

  .slick-dots {
    bottom: -$space-and-half;
    list-style: none;
    margin: $space-half 0 0;
    max-width: none;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;

    @include media ('>=l') {
      bottom: -$space-double;
    }

    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      text-align: center;
      vertical-align: middle;

      button {
        background-color: $african-violet;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: $space-third;
        margin-left: $space-quarter;
        margin-right: $space-quarter;
        opacity: 0.3;
        overflow: hidden;
        padding: 0;
        text-indent: 100%;
        transition: all 0.3s;
        white-space: nowrap;
        width: $space-third;

        @include media ('>=wide') {
          height: $space-half;
          width: $space-half;
        }

        .slick-dot-icon {
          &::before {
            display: none;
          }
        }

        &:hover,
        &:focus {
          opacity: 1;
          transform: scale(1.25);
        }

        &:focus-visible {
          outline: 1px solid $gold;
        }
      }
    }

    .slick-active button {
      opacity: 1;
    }
  }
}
