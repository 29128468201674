// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

// Home news and events panel
.home-news-events {
  background-color: $light-grey;
  padding: $space-double 0;

  .page-layout-wide-boxed &,
  .page-layout-home-boxed & {
    @extend %full-width;
  }
}

.home-news-events__header-social {
  align-items: start;
  display: flex;
  flex-flow: column wrap;
  margin-bottom: $space;

  @include media('≥xs-tweak') {
    align-items: center;
    flex-flow: row nowrap;
  }

  // Hide if there are no events
  .section-header__heading--events {
    &.no-events {
      display: none;
    }
  }
}


//
// Import styles
//
@import 'home/news';
@import 'home/events';
