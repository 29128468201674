// -----------------------------------------------------------------------------
// This file contains all styles related to the breadcrumb component.
// -----------------------------------------------------------------------------

//
// Outer container
//
.breadcrumbs {
  @include type-setting(-1);

  background-color: rgba($black, 0.25);
  display: none;
  font-family: $font-brand;
  padding-bottom: $space-third;
  padding-top: $space-third;


  // Hide when site menu is active or 'no-breadcrumbs' body class is added
  .site-menu-active &,
  .no-breadcrumbs & {
    display: none;
  }

  .page-layout-wide-boxed.page-staff-profile:not(.no-breadcrumbs) & {
    display: block;
  }

  .page-layout-wide-boxed.page-staff-profile .site-menu-active & {
    display: none;
  }
}





//
// Inner container
//
.breadcrumbs__container {
  @extend %container; // Extend default container styles
}





//
// List
//
.breadcrumbs__list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;

  @include media('≥m') {
    padding-left: math.div($space-five-sixths, 2); // Width of house icon
  }

  @include media('≥l') {
    padding-left: $space-half;
  }
}





//
// Individual breadcrumb item
// These are hidden from mobile (apart from the parent section item)
//
.breadcrumbs__item {
  align-items: center;
  display: none;
  margin-bottom: math.div($space-eighth, 2);
  margin-left: $space-third;
  margin-top: math.div($space-eighth, 2);

  @include media('≥m') {
    display: flex;
  }

  @include media('≥l') {
    margin-left: $space-half;
  }


  // Right chevron
  &::before {
    background-image: encode-svg('<svg viewBox="0 0 14 24" xmlns="http%3A//www.w3.org/2000/svg"><path d="M12.744 12L.168.874A.5.5 0 1 1 .83.126l13 11.5a.5.5 0 0 1 0 .748l-13 11.5a.5.5 0 1 1-.662-.748L12.744 12z" fill="#{$white}" /></svg>');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: math.div($space-five-sixths, 2);
    margin-right: $space-third;
    opacity: 0.6;
    width: $space-quarter;

    @include media('≥l') {
      margin-right: $space-half;
    }
  }


  // Links (can't use BEM because T4 automatically produces the link elements)
  a {
    color: $white;
    opacity: 0.6;
    position: relative;
    transition: opacity 0.3s;


    // Bottom border (hidden initially)
    &::after {
      background-color: $white;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: background-color 0.3s, opacity 0.3s;
      width: 100%;
    }


    // Increase opacity and show border on interaction
    &:hover,
    &:focus {
      opacity: 1;


      // Bottom border
      &::after {
        opacity: 1;
      }
    }
  }


  // First breadcrumb item (house icon)
  &:first-child {
    margin-left: -(math.div($space-five-sixths, 2)); // Move to left (width of icon)

    @include media('≥l') {
      margin-left: -$space-half;
    }


    // Hide right chevron
    &::before {
      display: none;
    }


    // Replace 'University home' text with house icon
    a {
      background-image: encode-svg('<svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><path d="M0 3.68v6.322h3.889v-3.89H6.11v3.89H10V3.68L5 0z" fill="#{$white}"/></svg>');
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
      border-bottom: 0;
      height: math.div($space-five-sixths, 2);
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      width: math.div($space-five-sixths, 2);

      @include media('≥l') {
        height: $space-half;
        width: $space-half;
      }


      // Hide bottom border
      &::after {
        display: none;
      }
    }
  }


  // Show only link to parent section on mobile
  &:nth-last-child(2) {
    display: flex;

    @include media('<m') {
      margin-left: 0;
    }


    // Left chevron on mobile
    &::before {
      @include media('<m') {
        background-image: encode-svg('<svg viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg"><path d="M1.254 12L13.83 23.126a.5.5 0 1 1-.662.748l-13-11.5a.5.5 0 0 1 0-.748l13-11.5a.5.5 0 1 1 .663.748L1.255 12z" fill="#{$white}" /></svg>');
      }
    }


    // Increase opacity
    a {
      @include media('<m') {
        opacity: 1;
      }
    }
  }


  // Highlight the last breadcrumb item (the current page)
  &:last-child {
    a {
      opacity: 1;


      // Hide bottom border
      &::after {
        display: none;
      }
    }
  }
}
