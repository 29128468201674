// -----------------------------------------------------------------------------
// This file contains main typography styles.
// -----------------------------------------------------------------------------

//
// Basic typography style for copy text
//
body {
  @include type-setting(0);

  color: $text-color;
  font-family: $font-regular;
  font-weight: $font-weight-normal;
}





//
// Global header styles
//
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-brand;
  font-weight: $font-weight-normal;
  letter-spacing: -0.05rem;
  margin-bottom: $space-half;
  margin-top: 0;
}

.page-heading,
.heading,
.subheading,
.sub-subheading,
.content-type--main .editor h2,
.content-type--main .editor h3,
.content-type--main .editor h4,
.content-type--main .editor h5,
.content-type--main .editor h6 {
  color: $african-violet;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}





//
// Global type styles
//
p,
ul,
ol,
dl,
table {
  margin-bottom: $space;
  margin-top: 0;
}





//
// Page heading
//
.page-heading {
  @extend %page-heading;
}





//
// Heading and subheading with icon
//
.heading,
.subheading {
  position: relative;

  .icon {
    display: block;
    height: $space-and-half;
    margin-bottom: $space-third;
    width: 100%;

    @include media('≥wider') {
      left: -($space-double + $space-half);
      position: absolute;
      width: $space-double;
    }
  }

  .icon__svg {
    fill: $african-violet;
    width: auto;

    @include media('≥wider') {
      width: 100%;
    }
  }


  // Add a bit of space to the right when heading has an icon and when in a
  // column
  .col &.has-icon {
    @include media('≥wider') {
      padding-right: $space-and-half;
    }
  }
}





//
// Heading
//
.heading {
  @extend %heading;

  .icon {
    @include media('≥wider') {
      top: $space-quarter;
    }
  }
}





//
// Subheading
//
.subheading {
  @extend %subheading;

  .icon {
    height: $space;

    @include media('≥wider') {
      height: $space-and-half;
      top: 0;
    }
  }
}





//
// Sub-subheading
//
.sub-subheading {
  @extend %sub-subheading;
}





//
// Editor styles
//
.editor {
  h2 {
    @extend %heading;

    margin-top: 0;
  }

  h3 {
    @extend %subheading;

    margin-top: 0;
  }

  h4 {
    @extend %sub-subheading;

    margin-top: 0;
  }

  h5 {
    @include type-setting(2);

    margin-top: $space;
  }

  h4 + h5 {
    margin-top: 0;
  }

  ul,
  ol {
    margin-bottom: $space;
    margin-left: 0;
    padding-left: 0;

    li {
      margin-bottom: $space-quarter;
      padding-left: $space-quarter;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-bottom: $space-quarter;
      margin-left: $space;
      margin-top: $space-quarter;
    }
  }

  ul {
    list-style: square;
    margin-left: $space;
  }

  ol {
    list-style: decimal;
    margin-left: $space;

    &[type="a"] {
      list-style: lower-alpha;
    }

    &[type="i"] {
      list-style: lower-roman;
    }
  }

  blockquote {
    @include type-setting(2);

    font-family: $font-brand;
    font-style: italic;
    margin-left: $space-and-half;
    margin-right: $space;
    padding: 0;
    position: relative;

    @include media('≥l') {
      margin-left: $space-double;
    }

    @include media('≥ultra-wide') {
      margin-left: 0;
    }

    &::before {
      content: '“';
      display: block;
      font-size: 72px;
      left: -($space-double + $space-quarter);
      line-height: 1;
      position: absolute;
      top: 0;

      @include media('≥l') {
        font-size: 96px;
        left: -($space-double + $space-three-quarters);
        top: -2px;
      }

      @include media('≥ultra-wide') {
        left: -$space-treble;
      }
    }
  }
}
