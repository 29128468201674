// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

//
// Global footer
//
.global-footer {
  background-color: $black;
  color: $white;
  font-family: $font-brand;
  margin-top: $space-five-sixths;
  padding-bottom: $space-and-third;
  padding-top: $space;

  @include media('≥m') {
    margin-top: $space-quadruple;
    padding-bottom: $space-and-half;
  }

  @include media('≥l') {
    padding-bottom: 0;
    padding-top: $space-double;
  }

  @include media('≥wide') {
    padding-bottom: $space-two-thirds;
    padding-top: $space-treble;
  }

  .list {
    @extend %module;
    @include type-setting(1);

    margin-bottom: $space-and-half;

    @include media('≥l') {
      @include type-setting(0);
    }
  }

  .list__item {
    margin-bottom: $space-quarter;

    @include media('≥l') {
      margin-bottom: $space-twelth;
    }
  }

  .page-footer + & {
    margin-top: 0;
  }


  // Remove top margin when there's a site footer
  .site-footer + & {
    margin-top: 0;
  }
}





//
// Site footer
// Apply same top margin as global footer
//
.site-footer {
  margin-top: $space-five-sixths;

  @include media('≥m') {
    margin-top: $space-quadruple;
  }
}





//
// Links
//
.footer__links {
  @include media('≥m') {
    float: left;
    position: relative;
    width: percentage(math.div(9, 12));
    z-index: 10;
  }
}





//
// Column
//
.footer__column {
  @include media('≥l') {
    float: left;
    padding-right: $gutter;
    position: relative;
    width: percentage(math.div(4, 12));
  }
}





//
// Heading
//
.footer__heading {
  @include type-setting(4);

  border-bottom: 1px solid rgba($white, 0.3);
  cursor: pointer;
  font-family: $font-brand-medium;
  padding-bottom: $space-half;
  position: relative;
  transition: border-color 0.3s;

  @include media('≥l') {
    @include type-setting(2);

    border-bottom: 0;
    cursor: text;
    margin-bottom: $space-three-quarters;
    padding-bottom: 0;
  }

  &:hover,
  &:focus {
    border-bottom-color: rgba($white, 0.6);
  }

  &::before,
  &::after {
    background-color: $white;
    content: '';
    display: block;
    margin-top: -1px;
    position: absolute;
    right: 0;
    top: 15px;

    @include media('≥l') {
      display: none;
    }
  }

  &::before {
    height: 1px;
    transition: opacity 0.3s;
    width: 19px;
  }

  &::after {
    height: 19px;
    margin-right: 9px;
    margin-top: -10px;
    transition: transform 0.3s;
    width: 1px;
  }

  &.active {
    border-bottom-color: $white;

    &::before {
      opacity: 0;
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &.footer__toggle-content-heading {
    @extend %visually-hidden-mobile;
  }

  .footer__toggle & {
    border-bottom: 0;
    font-family: $font-brand-medium;
    font-weight: $font-weight-normal;
    letter-spacing: -0.05rem;
    margin-bottom: $space-half;
    margin-top: 0;

    &::before,
    &::after {
      display: none;
      visibility: hidden;
    }
  }
}





//
// Content
//
.footer__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s;

  @include media('≥l') {
    max-height: inherit;
    overflow: visible;
  }

  &.active {
    max-height: 500px;
  }
}





//
// Toggle
//
.footer__toggle {
  margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.footer__toggle-button.button {
  background-color: transparent;
  padding-left: 0;

  @include media('≤l') {
    border-bottom: 1px solid rgba($white, 0.3);
    cursor: pointer;
    padding-top: $space-half;
    transition: border-color 0.3s;
  }

  @include media('≥l') {
    display: none;
    padding-top: 0;
    visibility: hidden;

    &::before,
    &::after {
      display: none;
      visibility: hidden;
    }

    .footer__heading {
      display: none;
      visibility: hidden;
    }
  }

  &::before,
  &::after {
    right: 0;
  }

  &:focus,
  &:hover {
    background-color: transparent;
    border-bottom-color: rgba($white, 0.6);
  }

  &:focus-visible {
    outline: rgba($white, 0.6) 2px solid;
    outline-offset: 5px;
  }

  .footer__toggle.active & {
    background-color: transparent;
    border-bottom-color: $white;
  }
}

.toggle__content-container {
  .footer__toggle & {
    padding: $space-half 0 0;

    @include media('≥l') {
      padding: 0;
    }

    .list {
      @include media('≤l') {
        margin-bottom: $space;
      }
    }
  }
}

.toggle__footer-content {
  .global-footer & {
    @include media('≥l') {
      display: block !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}





//
// Link
//
.footer__link {
  border-bottom: 1px solid transparent;
  color: $white;
  opacity: 0.6;
  z-index: 20;

  &:hover,
  &:focus {
    color: $white;
    opacity: 1;
  }
}





//
// Locations column
//
.footer__column--locations {
  /*
  .footer__heading {
    margin-bottom: $space-quarter;
  }
  */

  .footer__toggle-button {
    @include media('≥l') {
      padding-bottom: $space-quarter;
    }
  }
}





//
// Location
//
.footer__location {
  display: inline-block;
  margin-left: -$space-three-quarters;
  margin-right: $space-half;
  padding: $space-three-quarters;
  transition: background-color 0.3s;
  vertical-align: top;

  .footer__logo {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin-bottom: $space-half;
    width: 180px;
  }

  .list {
    margin-bottom: 0;
  }

  .list__item {
    margin-right: $space-third;
    padding-left: $space-double - $space-twelth;
    position: relative;
  }

  .footer__link {
    &:hover,
    &:focus {
      border-bottom: 1px solid $white;
    }
  }

  .icon {
    height: $space-two-thirds;
    left: $space-third;
    margin-top: -$space-third;
    position: absolute;
    top: 50%;
    width: $space;

    &--footer-email {
      left: $space-half;
      width: $space-two-thirds;
    }
  }

  .icon__svg {
    fill: $white;
  }

  &:hover,
  &:focus,
  &.active {
    .footer__link {
      opacity: 1;
    }
  }
}





//
// Loughborough location
//
.footer__location--lboro {
  .footer__logo {
    background-image: url('../images/logos/lboro.svg');
    height: 46px;
  }

  &:hover,
  &:focus,
  &.active {
    @include media('≥l') {
      background-color: $african-violet;
    }
  }
}





//
// London location
//
.footer__location--london {
  .footer__logo {
    background-image: url('../images/logos/london.svg');
    height: 59px;
    margin-bottom: $space-three-quarters;
  }

  &:hover,
  &:focus,
  &.active {
    @include media('≥l') {
      background-color: $mulberry;
    }
  }
}





//
// Map column
//
.footer__column--map {
  display: none;

  @include media('≥m') {
    display: block;
    float: right;
    padding-left: $gutter;
    padding-right: 0;
    width: percentage(math.div(3, 12));
  }

  @include media('≥l') {
    padding-left: 0;
  }
}





//
// Map
//
.footer__map {
  .icon {
    height: $space-and-half;
    position: absolute;
    width: $space;
  }
}





//
// Footer marker
//
.footer__marker {
  @include media('≥l') {
    transform-origin: bottom;
    transition: transform 0.3s;
  }

  &.active {
    @include media('≥l') {
      transform: scale(1.25);
      transform-origin: bottom;
    }
  }
}





//
// Loughborough footer marker
//
.footer__marker--lboro {
  bottom: 30%;
  right: 19%;

  @include media('≥l') {
    bottom: 31%;
    right: 21.4%;
  }
}





//
// Loughborough footer marker
//
.footer__marker--london {
  bottom: 17%;
  right: 8.5%;

  @include media('≥l') {
    bottom: 18%;
    right: 10.4%;
  }
}





//
// Social
//
.footer__social {
  clear: both;
  padding-top: $space;
  position: relative;
  text-align: center;

  @include media('≥m') {
    text-align: left;
  }

  @include media('≥l') {
    padding-left: percentage(math.div(6, 12));
    padding-top: 0;
    top: -($space-treble + $space-sixth);
  }



  // Link - updated version - to stay after publishing has taken place
  .footer__social-link {
    display: inline-block;
    margin-bottom: $space-two-thirds;
    margin-right: $space-half;
    position: relative;
    transition: transform 0.3s;
    z-index: 10;

    &:hover,
    &:focus {
      transform: scale(1.2);
    }

    // Icon
    .icon {
      height: $space-and-half;
      width: $space-and-half;
    }
  }


  // To be removed after publishing has taken place
  .footer__link {
    opacity: 1;
    z-index: 10;

    &.icon {
      height: $space-and-half;
      margin-left: $space-sixth;
      margin-right: $space-sixth;
      transition: transform(1.1);
      width: $space-and-half;

      @include media('≥xs') {
        margin-left: $space-quarter;
        margin-right: $space-quarter;
      }

      @include media('≥xs') {
        margin-left: $space-third;
        margin-right: $space-third;
      }

      @include media('≥m') {
        margin-left: 0;
        margin-right: $space-half;
      }

      &:hover,
      &:focus {
        transform: scale(1.2);
      }
    }
  }
}





//
// Legal
//
.footer__legal {
  background-color: darken($asphalt, 25%);
  color: $white;
  font-family: $font-brand;
  padding-bottom: $space;
  padding-top: $space;
  text-align: center;

  @include media('≥s') {
    padding-bottom: $space-three-quarters;
    padding-top: $space-three-quarters;
  }

  @include media('≥m') {
    padding-bottom: $space;
    padding-top: $space;
  }

  @include media('≥l') {
    padding-bottom: $space-and-quarter;
    padding-top: $space-and-quarter;
  }
}





//
// Copyright
//
.footer__copyright {
  border-top: 1px solid rgba($white, 0.5);
  margin: $space 0 0;
  opacity: 0.6;
  padding-top: $space;

  @include media('≥s') {
    border: 0;
    float: left;
    margin: 0;
    padding: 0;
  }
}

.footer__copyright-year,
.footer__copyright-rights {
  display: inline-block;
}





//
// Back to top link
//
.footer__top {
  display: inline-block;
  padding-top: $space-half;
  position: relative;

  @include media('≥s') {
    background-position: left center;
    float: right;
    padding-left: $space;
    padding-top: 0;
  }

  &::before {
    border-style: solid;
    border-width: 1px 0 0 1px;
    content: '';
    display: inline-block;
    height: 10px;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    transition: top 0.2s;
    vertical-align: top;
    width: 10px;

    @include media('≥s') {
      left: 0;
      margin-left: 0;
      top: 50%;
    }
  }

  &:hover,
  &:focus {
    &::before {
      top: -$space-eighth;

      @include media('≥s') {
        top: 25%;
      }
    }
  }
}





//
// Footer contact
//
.footer-contact {
  background-color: $african-violet;
  font-family: $font-regular;
  margin-top: $space-half;
  overflow: hidden;
  padding-bottom: $space;
  padding-top: $space;
  position: relative;
  text-align: center;

  @include media('≥s') {
    padding-bottom: $space;
    padding-top: $space;
  }

  @include media('≥m') {
    padding-bottom: $space-and-half;
  }

  @include media('≥l') {
    margin-top: $space-double;
    padding-bottom: $space-double;
    padding-top: $space-and-half;
  }


  // Container
  .container {
    position: relative;
    z-index: 10;
  }


  // Heading
  .footer-contact__heading {
    @include type-setting(8);

    color: $white;
    font-family: $font-brand;
    margin-bottom: $space-two-thirds;

    @include media('≥xs') {
      margin-bottom: $space;
    }

    @include media('≥l') {
      margin-bottom: $space-and-half;
    }
  }



  // List item heading
  .list__item {
    .list__heading {
      font-family: $font-brand;
    }
  }
}
