// -----------------------------------------------------------------------------
// This file contains all layout styles related to the sidebar
// -----------------------------------------------------------------------------

//
// Main sidebar styles and component specific styling
//
.sidebar {
  padding-top: $space;

  @include media('≥m') {
    float: right;
    padding-top: $space-double;
    width: percentage(math.div(3.5, 12));
  }

  @include media('≥l') {
    width: percentage(math.div(3, 12));
  }

  .heading {
    @include type-setting(4);

    margin-top: 0;
  }

  .button {
    @include media('<xs') {
      display: block;
    }
  }
}





//
// Sidebar sections
//
.sidebar__section {
  margin-bottom: $space-and-half;


  // Content type container
  .content-type__container {
    @include media('≥m') {
      padding-left: $space-and-quarter;
      padding-right: $space-and-quarter;
    }
  }


  // Cards
  .card {
    @include media('≥s') {
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}





//
// Sidebar headings
//
.sidebar__heading {
  @extend %subheading;

  margin-top: 0;
}





//
// Sidebar navigation
//
.sidebar__nav {
  @extend %module;
  @include type-setting(1);

  background-color: $light-grey;
  font-family: $font-brand;
  padding: $space-and-quarter $space-and-quarter $space-and-half;

  .list__item {
    margin-bottom: $space-half;
  }

  a {
    display: block;
  }
}





//
// Primary sidebar navigation
//
.sidebar__nav--primary {
  margin-bottom: $space-and-half;
  margin-left: -$space-five-sixths;
  margin-right: -$space-five-sixths;

  @include media('<m') {
    margin-top: -$space;
    padding: 0;
  }

  @include media('≥m') {
    margin-bottom: $space-double;
    margin-left: 0;
    margin-right: 0;
  }

  .sidebar__heading {
    background-color: darken($african-violet, 8%);
    color: $white;
    cursor: pointer;
    margin: 0;
    padding: $space-half $space-five-sixths;
    position: relative;
    transition: background-color 0.3s, color 0.3s;

    @include media('≥m') {
      display: none;
    }

    &::before,
    &::after {
      background-color: $white;
      content: '';
      display: block;
      margin-top: -1px;
      position: absolute;
      right: $space-five-sixths;
      top: 50%;
      transition: background-color 0.3s, opacity 0.3s, transform 0.3s;

      @include media('≥l') {
        display: none;
      }
    }

    &::before {
      height: 1px;
      transition: opacity 0.3s;
      width: 19px;
    }

    &::after {
      height: 19px;
      margin-right: 9px;
      margin-top: -10px;
      transition: transform 0.3s;
      width: 1px;
    }

    &:hover,
    &:focus {
      background-color: darken($african-violet, 4%);
    }

    &.active {
      background-color: rgba($black, 0.075);
      color: $asphalt;

      &::before {
        background-color: $text-color;
        opacity: 0;
      }

      &::after {
        background-color: $text-color;
        transform: rotate(90deg);
      }
    }
  }

  .sidebar__container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s;

    @include media('≥m') {
      max-height: inherit;
      overflow: visible;
    }

    &.active {
      max-height: 2000px;
    }
  }

  .sidebar__content {
    padding: $space-three-quarters $space-five-sixths $space;

    @include media('≥m') {
      padding: 0;
    }
  }

  .sidebar__subheading {
    @include type-setting(3);

    font-family: $font-brand-medium;
    margin-bottom: 0;

    .sidebar__link {
      position: relative;
    }

    &.active {
      .sidebar__link {
        @extend %sidebar-nav-chevron;
      }
    }
  }

  .list {
    margin-top: $space-half;
  }

  .list__item {
    &.active {
      background-color: $mulberry;
      margin-left: -$space-and-quarter;
      margin-right: -$space-and-quarter;
      padding-bottom: $space-half;
      padding-left: $space-and-quarter;
      padding-right: $space-and-quarter;
      padding-top: $space-half;

      .list__link,
      span a {
        color: $white;
      }
    }
  }

  li {
    margin-bottom: $space-quarter;

    a {
      position: relative;
    }

    &.active {
      .list__link,
      span a {
        @extend %sidebar-nav-chevron;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      .list__link {
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    &.has-children > span a::before {
      display: none;
    }

    ul {
      @include type-setting(0);

      list-style: none;
      margin: 0;
      padding: 0;

      a {
        color: $white;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .multilevel-linkul-0 {
    margin-top: $space-half;

    a {
      padding-left: $space-third;
    }
  }

  .multilevel-linkul-1,
  .multilevel-linkul-2,
  .multilevel-linkul-3 {
    margin-top: $space-quarter;
  }

  .multilevel-linkul-1 a {
    padding-left: $space-two-thirds;
  }

  .multilevel-linkul-2 a {
    padding-left: $space;
  }

  .multilevel-linkul-3 a {
    padding-left: $space-and-third;
  }
}





//
// Secondary sidebar navigation
//
.sidebar__nav--secondary {
  background-color: transparent;
  border: 1px solid rgba($asphalt, 0.25);

  .list__link--external {
    .list__text {
      padding-right: $space-quarter;
    }

    .icon {
      position: relative;
      top: -3px;
    }

    .icon__svg {
      fill: $mulberry;
    }

    &:hover,
    &:focus {
      .icon__svg {
        fill: $african-violet;
      }
    }
  }
}
