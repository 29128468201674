// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//
// Font families
//

// Regular font family
$font-regular: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

// Brand font family
$font-brand:        'DIN', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-light:  'DIN Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-medium: 'DIN Medium', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-bold:   'DIN Bold', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-black:  'DIN Black', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

// Round brand font family
$font-brand-round:        'DIN Round', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-round-light:  'DIN Round Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-round-medium: 'DIN Round Medium', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-round-bold:   'DIN Round Bold', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-round-black:  'DIN Round Black', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

// Condensed brand font family
$font-brand-condensed:        'DIN Condensed', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-condensed-light:  'DIN Condensed Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-condensed-medium: 'DIN Condensed Medium', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-condensed-bold:   'DIN Condensed Bold', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-brand-condensed-black:  'DIN Condensed Black', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;





//
// Font weights
//

// Light font weight
$font-weight-light:   300 !default;

// Normal font weight
$font-weight-normal:  400 !default;

// Medium font weight
$font-weight-medium:  500 !default;

// Bold font weight
$font-weight-bold:    700 !default;

// Black font weight
$font-weight-black:   900 !default;





//
// Type scale
//

// Modular scale config
$modularscale: (
  ratio: 1.125,
  600px: (
    base: 0.8rem,
  ),
  960px: (
    base: 0.9rem
  ),
  1220px: (
    base: 1rem
  )
);


// Type settings map (http://www.modularscale.com/?1&rem&1.125)
$type-settings: (
  -2: (
    type-scale: -2, // 0.79rem ((12.64px)
    line-height: 1.582278481 // 1.25rem / 0.79rem
  ),
  -1: (
    type-scale: -1, // 0.889rem (14.22px)
    line-height: 1.406074241 // 1.25rem / 0.889rem
  ),
  0: (
    type-scale: 0, // 1rem (16px)
    line-height: 1.5 // 1.5rem / 1rem
  ),
  1: (
    type-scale: 1, // 1.125rem (18px)
    line-height: 1.555555556 // 1.75rem / 1.125rem
  ),
  2: (
    type-scale: 2, // 1.266rem (20.25px)
    line-height: 1.382306477 // 1.75rem / 1.266rem
  ),
  3: (
    type-scale: 3, // 1.424rem (22.78px)
    line-height: 1.404494382 // 2rem / 1.424rem
  ),
  4: (
    type-scale: 4, // 1.602rem (25.63px)
    line-height: 1.404494382 // 2.25rem / 1.602rem
  ),
  5: (
    type-scale: 5, // 1.802rem (28.83px)
    line-height: 1.387347392 // 2.5rem / 1.802rem
  ),
  6: (
    type-scale: 6, // 2.027rem (32.44px)
    line-height: 1.356684756 // 2.75rem / 2.027rem
  ),
  7: (
    type-scale: 7, // 2.281rem (36.49px)
    line-height: 1.315212626 // 3rem / 2.281rem
  ),
  8: (
    type-scale: 8, // 2.566rem (41.05px)
    line-height: 1.266562744 // 3.25rem / 2.566rem
  ),
  9: (
    type-scale: 9, // 2.887rem (46.18px)
    line-height: 1.21233114 // 3.5rem / 2.887rem
  ),
  10: (
    type-scale: 10, // 3.247rem (51.96px)
    line-height: 1.154912227 // 3.75rem / 3.247rem
  ),
  11: (
    type-scale: 11, // 3.653rem (58.45px)
    line-height: 1.16342732 // 4.25rem / 3.653rem
  ),
  12: (
    type-scale: 12, // 4.11rem (65.76px)
    line-height: 1.094890511 // 4.5rem / 4.11rem
  ),
  13: (
    type-scale: 13, // 4.624rem (73.98px)
    line-height: 1.081314879 // 5rem / 4.624rem
  ),
  14: (
    type-scale: 14, // 5.202rem (83.23px)
    line-height: 1.057285659 // 5.5rem / 5.202rem
  ),
  15: (
    type-scale: 15, // 5.852rem (93.63px)
    line-height: 1.068010936 // 6.25rem / 5.852rem
  ),
  16: (
    type-scale: 16, // 6.583rem (105.33px)
    line-height: 1.025368373 // 6.75rem / 6.583rem
  ),
  17: (
    type-scale: 17, // 7.406rem ((118.50px)
    line-height: 1.012692412 // 7.5rem / 7.406rem
  ),
  18: (
    type-scale: 18, // 8.332rem (133.31px)
    line-height: 1.020163226 // 8.5rem / 8.332rem
  ),
  19: (
    type-scale: 19, // 9.373rem (149.97px)
    line-height: 1.013549557 // 9.5rem / 9.373rem
  ),
  20: (
    type-scale: 20, // 10.545rem (168.72px)
    line-height: 1.043148412 // 11rem / 10.545rem
  )
);





//
// Colours
//

// Brand colours
// stylelint-disable color-no-hex
$african-violet:        #361163 !default;
$african-violet-light:  #6f3092 !default;
$mulberry:              #b70062 !default;
$mulberry-light:        #ed2482 !default;
$mulberry-light-aa:     #e11a81 !default;
$mercia-red:            #a70531 !default;
$mercia-red-light:      #ee2f4f !default;
$mercia-red-light-aa:   #d52a47 !default;
$bronze:                #f26a38 !default;
$bronze-aa:             #cf4a2a !default;
$bronze-dark:           #98331d !default;
$gold:                  #e77e1d !default;
$gold-aa:               #c75302 !default;
$gold-light:            #fbaa1b !default;
$marigold:              #bd8508 !default;
$marigold-aa:           #9c6e07 !default;
$marigold-light:        #e7ad00 !default;
$canary:                #fcd839 !default;
$cedar:                 #b1be27 !default;
$cedar-aa:              #6f7e0d !default;
$cedar-light:           #e1ca00 !default;
$olympic-green:         #008466 !default;
$olympic-green-aa:      #128851 !default;
$olympic-green-dark:    #006e55 !default;
$neptune:               #006167 !default;
$neptune-light:         #78b2bb !default;
$neptune-light-aa:      #26828e !default;
$peacock:               #00aaad !default;
$peacock-aa:            #2d8289 !default;
$peacock-light:         #54c6d5 !default;
$petrol-blue:           #005e82 !default;
$petrol-blue-light:     #009bc9 !default;
$petrol-blue-light-aa:  #007da3 !default;
$asphalt:               #525e66 !default;
$silver:                #8c8c8d !default;
$silver-aa:             #767677 !default;
$fountain-grey:         #cbcece !default;
$pebble:                #715f54 !default;
$dove-grey:             #a89b91 !default;
$pearl-white:           #d2c9c1 !default;
$black:                 #000 !default;
$dark-grey:             #1b1b1b !default;
$light-grey:            #f6f7f7 !default;
$white:                 #fff !default;
$text-color:            $asphalt !default;
$text-color-light:      lighten($text-color, 5%) !default;
$text-color-lightest:   lighten($text-color, 10%) !default;

// Project colours
// $variable-name: #000;
// stylelint-enable color-no-hex





//
// Container's maximum width
//
$max-width: 1180px !default;





//
// Gutters
//
$gutter:          2rem !default;
$gutter-half:     math.div($gutter, 2) !default;
$gutter-third:    math.div($gutter, 3) !default;
$gutter-quarter:  math.div($gutter, 4) !default;
$gutter-sixth:    math.div($gutter, 6) !default;





//
// Spacing
//
$space:                     1.5rem !default; // 24px
$space-twelth:              math.div($space, 12) !default; // 2px
$space-eighth:              math.div($space, 8) !default; // 3px
$space-sixth:               math.div($space, 6) !default; // 4px
$space-quarter:             math.div($space, 4) !default; // 6px
$space-third:               math.div($space, 3) !default; // 8px
$space-half:                math.div($space, 2) !default; // 12px
$space-two-thirds:          $space-third * 2 !default; // 16px
$space-three-quarters:      $space-quarter * 3 !default; // 18px
$space-five-sixths:         $space-sixth * 5 !default; // 20px
$space-and-eighth:          $space + $space-eighth !default; // 27px
$space-and-sixth:           $space + $space-sixth !default; // 28px
$space-and-quarter:         $space + $space-quarter !default; // 30px
$space-and-third:           $space + $space-third !default; // 32px
$space-and-half:            $space + $space-half !default; // 36px
$space-and-two-thirds:      $space + $space-two-thirds !default; // 40px
$space-and-three-quarters:  $space + $space-three-quarters !default; // 42px
$space-double:              $space * 2 !default; // 48px
$space-treble:              $space * 3 !default; // 72px
$space-quadruple:           $space * 4 !default; // 96px





//
// Media query breakpoints
//
$breakpoints: (
  'xs': 400px,
  'xs-tweak': 500px,
  's': 600px,
  's-tweak': 680px,
  'm': 760px,
  'm-tweak': 860px,
  'l': 960px,
  'xl': 1020px,
  'max': $max-width,
  'wide': 1220px,
  'wider': 1336px,
  'ultra-wide': 1440px,
  'super-wide': 1600px
);





//
// Advanced banner (advb) specific variables
//
$advb-height-mobile-slim: 350px;
$advb-height-mobile-medium: 400px;
$advb-height-mobile-tall: 450px;
$advb-height-desktop-slim: 450px;
$advb-height-desktop-medium: 600px;
$advb-height-desktop-tall: 750px;

$advb-container-max-width: 1180px;

$advb-overlay-dark: rgba($black, 0.65);
$advb-overlay-light: rgba($white, 0.8);
