// -----------------------------------------------------------------------------
// This file contains all styles related to the alert component
// -----------------------------------------------------------------------------

//
// Main alert styles
//
.alert {
  @extend %module;
  @include clearfix;

  margin-bottom: $space;
  padding: $space-two-thirds $space-three-quarters;
  position: relative;

  @include media('≥m') {
    padding: $space $space $space ($space-treble + $space-third);
  }

  .icon {
    float: right;
    margin-bottom: $space-half;
    margin-left: $space-half;

    @include media('≥m') {
      float: none;
      height: $space-and-half;
      left: $space;
      margin-bottom: 0;
      margin-left: 0;
      position: absolute;
      top: $space-three-quarters;
      width: $space-and-half;
    }
  }
}





//
// Default alert
//
.alert--default {
  background-color: $light-grey;

  .icon__svg {
    fill: $text-color;
  }
}





//
// Subtle alert
//
.alert--subtle {
  background-color: $white;
  border: 1px solid $fountain-grey;

  .icon__svg {
    fill: $text-color;
  }
}




//
// Standout alert
//
.alert--standout {
  background-color: $african-violet;
  color: $white;

  .icon__svg {
    fill: $white;
  }

  a:not(.button),
  .alert__link {
    &,
    &:hover,
    &:focus {
      border-bottom-color: $white;
      color: $white;
    }
  }
}





//
// Danger alert
//
.alert--danger {
  background-color: lighten($mercia-red-light, 34%);
  border: 1px solid $mercia-red;
  color: $mercia-red;

  .icon__svg {
    fill: $mercia-red;
  }

  a:not(.button),
  .alert__link {
    &,
    &:hover,
    &:focus {
      border-bottom-color: $mercia-red;
      color: $mercia-red;
    }
  }

  .button {
    background-color: $mercia-red;
    color: $white;

    &:hover,
    &:focus {
      background-color: darken($mercia-red, 10%);
      color: $white;
    }
  }
}





//
// Danger alert
//
.alert--warning {
  background-color: lighten($gold, 43%);
  border: 1px solid $gold;
  color: darken($gold, 14%);

  .icon__svg {
    fill: $gold;
  }

  a:not(.button),
  .alert__link {
    &,
    &:hover,
    &:focus {
      border-bottom-color: darken($gold, 14%);
      color: darken($gold, 14%);
    }
  }

  .button {
    background-color: darken($gold, 14%);
    color: $white;

    &:hover,
    &:focus {
      background-color: darken($gold, 24%);
      color: $white;
    }
  }
}





//
// Success alert
//
.alert--success {
  background-color: lighten($olympic-green, 62%);
  border: 1px solid $olympic-green;
  color: darken($olympic-green, 8%);

  .icon__svg {
    fill: darken($olympic-green, 4%);
  }

  a:not(.button),
  .alert__link {
    &,
    &:hover,
    &:focus {
      border-bottom-color: darken($olympic-green, 8%);
      color: darken($olympic-green, 8%);
    }
  }

  .button {
    background-color: darken($olympic-green, 8%);
    color: $white;

    &:hover,
    &:focus {
      background-color: darken($olympic-green, 14%);
      color: $white;
    }
  }
}
