// -----------------------------------------------------------------------------
// This file contains all styles related to the toggle component.
// -----------------------------------------------------------------------------

.toggle {
  margin-bottom: $space-half;
}





//
// Toggle button
//
.toggle__button {
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  outline: 0;
  padding-right: $space-double + $space-half;
  position: relative;
  text-align: left;
  width: 100%;

  &::before,
  &::after {
    background-color: $white;
    content: '';
    display: block;
    margin-top: -1px;
    position: absolute;
    right: $space-three-quarters;
    top: 50%;
  }

  &::before {
    height: 1px;
    transition: opacity 0.3s;
    width: 23px;
  }

  &::after {
    height: 23px;
    margin-right: 11px;
    margin-top: -12px;
    transition: transform 0.3s;
    width: 1px;
  }

  .toggle.active & {
    background-color: $african-violet;
  }

  .toggle.active &,
  .footer__toggle.active & {
    &::before {
      opacity: 0;
    }

    &::after {
      transform: rotate(90deg);
    }
  }
}





//
// Content container
//
.toggle__content {
  .js & {
    display: none;
  }

  .js .toggle.active &,
  .js .footer__toggle.active & {
    display: block;
  }

  .js .footer__toggle & {
    @include media('≥l') {
      display: block;
    }
  }
}





//
// Content
//
.toggle__content-container {
  @extend %module;

  padding: $space $space-three-quarters $space-and-three-quarters;

  .image {
    margin-bottom: $space-three-quarters;
  }

  .image--right {
    @include media('≥s') {
      float: right;
      margin-left: $space-and-half;
      width: percentage(math.div(3, 12));
    }
  }

  .image--left {
    @include media('≥s') {
      float: left;
      margin-right: $space-and-half;
      width: percentage(math.div(3, 12));
    }
  }
}
