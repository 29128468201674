// -----------------------------------------------------------------------------
// This file contains all styles related to the quote component.
// -----------------------------------------------------------------------------

.quote {
  font-family: $font-brand;
  margin: $space-double 0;
  padding: 0 0 0 $space-and-half;

  @include media('≥l') {
    padding-left: $space-double;
  }

  @include media('≥ultra-wide') {
    padding-left: 0;
  }

  .image {
    float: right;
    margin-bottom: $space;
    margin-left: $space-double;
    width: percentage(math.div(3, 12));
  }
}

.quote__blockquote {
  @include type-setting(3);

  font-style: italic;
  margin: 0;
  padding: 0;
  position: relative;

  &::before {
    content: '“';
    display: block;
    font-size: 72px;
    left: -($space-double + $space-quarter);
    line-height: 1;
    position: absolute;
    top: 0;

    @include media('≥l') {
      font-size: 96px;
      left: -($space-double + $space-three-quarters);
    }

    @include media('≥ultra-wide') {
      left: -$space-treble;
    }
  }
}

.quote__source-name {
  @include type-setting(2);

  display: block;
  margin-bottom: $space-sixth;
}

.quote__source-extra {
  @include type-setting(1);

  display: block;
}
