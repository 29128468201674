// -----------------------------------------------------------------------------
// This file contains all styles related to the header
// -----------------------------------------------------------------------------

//
// Header
//
.header {
  background-color: $black;
  position: relative;
  transition: background-color 0.3s;

  &::after {
    background-image: linear-gradient(transparent, transparent);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: -20%;
    transition: opacity 2s, top 2s;
    width: 100%;
    z-index: -50;
  }

  &.global-mobile-menu-active,
  &.global-search-active,
  &.site-menu-active {
    height: 100%;
    left: 0;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  &.global-mobile-menu-active {
    background-color: $black;
  }

  &.global-search-active {
    background-color: $african-violet;
    transition: none;

    &::after {
      background-image: linear-gradient($mulberry 10%, transparent);
      opacity: 1;
      top: 0;
    }
  }

  &.site-menu-active {
    background-color: $african-violet;
  }

  .form--search {
    margin-top: $space-half;
    position: relative;

    @include media('≥m') {
      margin-top: $space-double;
    }

    .form__components--text {
      margin-bottom: $space-three-quarters;

      @include media('≥m') {
        margin-bottom: $space-and-half;
      }
    }

    .form__field {
      @include type-setting(4);

      border-bottom: 1px solid $white;
      color: $white;
      padding-bottom: $space-quarter;
      padding-left: 0;
      padding-right: $space-five-sixths;
      width: 100%;
    }

    .button {
      bottom: $space-third;
      margin: 0;
      padding: 0;
      right: 0;
    }
  }
}





//
// Header navigation
//
.header__nav {
  @include media('<m') {
    @include type-setting(2);
  }

  .list__item {
    margin-bottom: $space-three-quarters;

    @include media('≥m') {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  .list__link {
    display: block;
    position: relative;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}




//
// Global header
//
.global-header {
  background-color: $black;
  color: $white;
  font-family: $font-brand;
  font-weight: $font-weight-normal;

  .container {
    @include clearfix();

    position: relative;
  }

  &.global-search-active,
  &.global-mobile-menu-active {
    background-color: transparent;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.site-menu-active {
    background: rgba($black, 0.1);
  }
}





//
// Global logo and toggles container
//
.global-header__logo-toggles-container {
  @include media('<m') {
    @include clearfix();
  }

  .global-search-active & {
    @include clearfix();
  }
}





//
// Global menu and search toggles
//
.global-header__toggle {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;

  &:focus-visible {
    outline: 2px solid $white;
    outline-offset: $space-sixth;
  }
}





//
// Global search toggle
//
.global-header__toggle--search {
  display: none;

  @include media('≥m') {
    display: block;
    height: $space;
    opacity: 0.65;
    outline: 0;
    position: absolute;
    right: $space-five-sixths;
    top: $space-double - $space-quarter;
    transition: opacity 0.3s, transform 0.3s;
    width: $space;
  }

  @include media('≥l') {
    top: $space-double + $space-sixth;
  }

  @include media('≥wide') {
    right: 0;
  }

  &:hover,
  &:focus,
  .global-search-active & {
    opacity: 1;
    transform: scale(1.1);
  }

  &::before,
  &::after {
    background-color: $white;
    content: '';
    display: inline-block;
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform-origin: center;
    transition: opacity 0.3s;
    transition-delay: 0.15s;
    visibility: hidden;
    width: 100%;
  }

  &::before {
    transform: rotate(135deg);
  }

  &::after {
    transform: rotate(225deg);
  }

  .icon--ui-search-white {
    transition: 0.3s;
  }

  .global-search-active & {
    &::before,
    &::after {
      opacity: 1;
      transform-origin: center;
      transition-delay: 0;
      visibility: visible;
    }

    .icon--ui-search-white {
      height: 0;
      opacity: 0;
      transition: 0.3s;
      visibility: hidden;
      width: 0;
    }
  }

  .site-menu-active & {
    display: none;
  }
}





//
// Global menu toggle
//
.global-header__toggle--menu {
  display: block;
  float: right;
  height: 18px;
  margin-top: $space-and-quarter;
  outline: 0;
  position: relative;
  transition: transform 0.5s;
  width: 20px;

  @include media('≥m') {
    display: none;
  }

  &::before,
  &::after {
    background-color: rgba($white, 0.5);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: 0.3s;
    width: 100%;
  }

  &::before {
    top: $space-quarter;
  }

  &::after {
    bottom: $space-quarter;
  }

  &:hover,
  &:focus {
    &::before,
    &::after {
      background-color: $white;
    }
  }

  &.active {
    width: 18px;

    &::before,
    &::after {
      background-color: $white;
      top: 50%;
      transform-origin: center;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      bottom: auto;
      transform: rotate(-45deg);
    }
  }

  .site-menu-active & {
    display: none;
  }
}





//
// Logo
//
.global-header__logo {
  background-image: url('../images/logos/lboro.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  float: left;
  height: 38px;
  margin-bottom: $space-five-sixths;
  margin-top: 20px;
  width: 150px;

  @include media('≥m') {
    height: 45px;
    margin-bottom: $space;
    margin-top: $space;
    width: 174px;
  }

  @include media('≥l') {
    height: 51px;
    margin-bottom: $space-and-quarter;
    margin-top: $space-and-quarter;
    width: 198px;
  }
}





//
// Global search
//
.global-header__search {
  display: none;

  .form--search {
    .form__field {
      @include media('≥m') {
        @include type-setting(7);

        padding-right: $space-double;
      }
    }

    .button {
      @include media('≥m') {
        bottom: $space-two-thirds;
        height: $space-and-third;
        width: $space-and-third;
      }
    }

    .icon--ui-search-white {
      height: $space-five-sixths;
      vertical-align: top;
      width: $space-five-sixths;

      @include media('≥m') {
        height: $space-and-third;
        width: $space-and-third;
      }
    }

    .form__components--radio {
      margin-bottom: $space-half;

      @include media('≥m') {
        display: inline-block;
        margin-right: $space-and-half;
      }

      .form__radio {
        display: inline-block;
        vertical-align: middle;
      }

      .form__label {
        @include type-setting(1);

        display: inline-block;
        margin-left: $space-third;
        vertical-align: middle;
      }
    }
  }

  .global-mobile-menu-active &,
  .global-search-active & {
    display: block;
  }
}





//
// Header navigation container
//
.global-header__nav-container {
  display: none;

  @include media('≥m') {
    display: block;
    float: right;
    padding-right: $space-double + $space-half;
    padding-top: $space-two-thirds;
    text-align: right;
    width: percentage(math.div(9, 12));
  }

  @include media('≥l') {
    padding-right: $space-treble + $space-third;
    padding-top: $space-three-quarters;
  }

  .global-mobile-menu-active & {
    display: flex;
    flex-direction: column-reverse;
  }

  .site-menu-active & {
    @include media('≥m') {
      opacity: 0;
      visibility: hidden;
    }
  }
}





//
// Header navigation
//
.global-header__nav {
  .global-search-active & {
    display: none;
  }
}





//
// Primary header navigation
//
.global-header__nav--primary {
  @include type-setting(2);

  margin-top: $space;
  position: relative;

  @include media('≥m') {
    margin-top: 0;
  }

  .list__item {
    @include media('≥m') {
      @include type-setting(1);

      margin-left: $space-three-quarters;
    }

    @include media('≥l') {
      margin-left: $space-and-sixth;
    }

    @include media('≥max') {
      margin-left: $space-and-half;
    }
  }

  .list__link {
    color: $white;

    @include media('≥m') {
      color: rgba($white, 0.65);
      padding-bottom: $space-and-sixth;
    }

    @include media('≥l') {
      padding-bottom: $space-and-half;
    }

    &::after {
      @include media('≥m') {
        background-color: $mulberry;
        bottom: 0;
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        transition: 0.3s;
        width: 100%;
      }
    }

    &:hover,
    &:focus {
      &::after {
        @include media('≥m') {
          background-color: $mulberry;
          height: 5px;
        }
      }
    }
  }

  &::after {
    display: none;

    @include media('≥m') {
      background-color: rgba($white, 0.2);
      content: '';
      display: block;
      height: $space-and-sixth;
      margin-right: -1px;
      position: absolute;
      right: -$space-three-quarters;
      top: -3px;
      width: 1px;
      z-index: 50;
    }

    @include media('≥l') {
      height: $space-and-third;
      margin-right: 0;
      right: -$space-and-sixth;
      top: -2px;
    }
  }
}





//
// Secondary header navigation
//
.global-header__nav--secondary {
  margin-bottom: $space-three-quarters;
  margin-top: $space-half;

  @include media('≥m') {
    margin-bottom: $space-third;
    margin-top: 0;
  }

  @include media('≥l') {
    margin-bottom: $space-third;
  }

  .list__item {
    @include media('≥m') {
      margin-left: $space-half;
    }

    @include media('≥l') {
      margin-left: $space;
    }
  }

  .list__link {
    color: rgba($white, 0.5);

    &::after {
      @include media('≥m') {
        background-color: rgba($white, 0.65);
        bottom: -1px;
        content: '';
        height: 1px;
        left: 50%;
        position: absolute;
        transition: 0.3s;
        width: 0;
      }
    }

    &:hover,
    &:focus {
      &::after {
        @include media('≥m') {
          background-color: $white;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}




//
// Site header
//
.site-header {
  background-color: $african-violet;
  color: $white;
  font-weight: $font-weight-normal;


  /*
    Hide site header when search or mobile menu is active,
    or when 'hide-site-header' is applied to the page layout
  */
  &.global-search-active,
  &.global-mobile-menu-active,
  .hide-site-header & {
    display: none;
  }


  // Remove background when site menu is active
  &.site-menu-active {
    background: none;
  }


  // Container
  .container {
    padding-bottom: $space;
    padding-top: $space-three-quarters;
    position: relative;

    @include media('≥m') {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    @include media('≥l') {
      padding-bottom: $space-three-quarters + $space-eighth;
      padding-top: $space-half;
    }

    &::after {
      display: none;
    }
  }


  // Search icon
  .icon--ui-search-white {
    height: $space-five-sixths;
    vertical-align: top;
    width: $space-five-sixths;
  }
}





//
// Site header title
//
.site-header__title {
  @include type-setting(5);

  color: $white;
  display: inline-block;
  font-family: $font-brand-medium;
  position: relative;
  vertical-align: middle;

  @include media('≤m-tweak') {
    padding-right: $space-and-quarter;
  }

  @include media('≥l') {
    padding-bottom: $space-third;
    padding-top: $space-third;
  }


  // 'Back to home' icon (hide initially)
  .icon {
    left: -$space-and-half;
    margin-top: -$space-third;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: opacity 0.3s;
    visibility: visible;


    // Hide on homepages
    .home & {
      display: none;
    }
  }

  .icon__svg {
    fill: $white;
  }


  // Maintain text colour on interaction
  &:hover,
  &:focus {
    color: $white;


    // Show icon
    .icon {
      @include media('≥1280px') {
        opacity: 1;
      }
    }
  }
}





//
// Site header nav, search and toggle container
//
.site-header__nav-search-toggle-container {
  @include media('≥m') {
    display: inline-block;
    position: relative;
    right: 0;
    top: 3px;
    vertical-align: middle;

    @include media('≥l') {
      top: 4px;
    }
  }

  .site-menu-active & {
    margin-top: $space-and-quarter;

    @include media('≥m') {
      margin-top: 0;
    }
  }
}





//
// Site header nav and search container
//
.site-header__nav-search-container {
  @include media('≥m') {
    display: inline-block;
    vertical-align: middle;
  }

  .site-menu-active & {
    display: flex;
    flex-direction: column;

    @include media('≥m') {
      display: inline-block;
    }
  }
}





//
// Site header navigation
//
.site-header__nav {
  display: none;
  font-family: $font-brand;

  @include media('≥m') {
    display: inline-block;
    text-align: right;
    vertical-align: middle;
  }

  .list__item {
    @include media('≥m') {
      @include type-setting(1);

      margin-left: $space-three-quarters;
    }

    @include media('≥l') {
      margin-left: $space-and-sixth;
    }

    @include media('≥max') {
      margin-left: $space-and-half;
    }
  }

  .list__link {
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  .site-menu-active & {
    @include media('<m') {
      display: block;
      order: 2;
    }
  }
}





//
// Primary site header navigation
//
.site-header__nav--primary {
  margin: 0;


  // List item
  .list__item {
    vertical-align: bottom;

    @include media('≥m', '<l') {
      margin-left: $space-half;
    }
  }


  // External link
  .list__item--external {
    .list__text {
      display: inline-block;
      padding-right: $space-quarter;
      vertical-align: middle;
    }

    .icon {
      height: $space-two-thirds;
      width: $space-two-thirds;
    }

    .icon__svg {
      fill: rgba($white, 0.75);
      transition: fill 0.3s;
    }
  }


  // List link
  .list__link {
    @include media('≥m') {
      border-bottom: 1px solid transparent;
      transition: border 0.3s;
    }

    &.active {
      color: $white;

      @include media('≥m') {
        border-bottom: 1px solid $white;
      }
    }

    &:hover,
    &:focus {
      border-bottom-color: $white;

      .icon__svg {
        fill: $white;
      }
    }
  }
}





//
// Site header menu
//
.site-header__menu {
  display: none;
  padding-bottom: $space-double;
  text-align: left;

  .container {
    display: block;
    padding-bottom: 0;
    padding-top: 0;
  }

  .site-menu-active & {
    display: block;
  }
}





//
// Secondary site header navigation
//
.site-header__nav--secondary {
  text-align: left;
  width: 100%;

  .list__item {
    @include media('≥m') {
      display: inline-block;
      margin-left: 0;
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: percentage(math.div(2.4, 12));
    }
  }

  .list__container {
    @include media('≥m') {
      padding: $space-sixth;
    }
  }

  .list__link {
    color: $white;

    @include media('≥m') {
      background-color: rgba($white, 0.1);
      padding: $space $space-half;
    }

    @include media('≥l') {
      padding: $space;
    }

    &:hover,
    &:focus {
      @include media('≥m') {
        background-color: rgba($black, 0.1);
      }
    }

    .icon {
      display: none;

      @include media('≥m') {
        display: block;
        height: 56px;
        margin-bottom: $space-half;
        width: 100%;
      }
    }

    .icon__svg {
      fill: $white;
    }
  }
}





//
// Site header search
//
.site-header__search {
  display: none;

  @include media('≥m') {
    display: inline-block;
    vertical-align: middle;
  }

  .form--search {
    margin-bottom: $space-and-half;
    margin-top: 0;

    @include media('≥m') {
      margin-bottom: 0;
      margin-left: $space-three-quarters;
      margin-top: 0;
      width: $space * 6;
    }

    @include media('≥l') {
      margin-left: $space-and-sixth;
    }

    @include media('≥max') {
      margin-left: $space-and-half;
    }

    &::after {
      @include media('≥m') {
        background-color: rgba($white, 0.35);
        bottom: -$space-quarter;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    .form__components {
      @include media('≥m') {
        margin: 0;
      }
    }

    .form__field {
      @include media('≥m') {
        @include type-setting(1);

        border: 0;
        line-height: 1;
        padding-bottom: 0;
        position: relative;
        top: 1px;
      }
    }

    .button {
      @include media('≥m') {
        bottom: $space-eighth;
        padding: 0;
      }
    }
  }

  .site-menu-active & {
    @include media('<m') {
      display: block;
      order: 1;
    }
  }
}





//
// Site header toggle
//
.site-header__toggle {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: calc(11px + 0.5rem);
  outline: 0;
  padding: 0;
  position: absolute;
  right: $space-five-sixths;
  top: 1.675rem;
  width: $space-five-sixths;

  @include media('≤m') {
    &:focus-visible {
      outline: 2px solid $white;
      outline-offset: $space-sixth;
    }
  }

  @include media('≥m') {
    &:focus-visible {
      outline: 1px solid $white;
    }
  }

  @include media('≥m') {
    @include type-setting(1);

    background-color: rgba($black, 0.1);
    color: $white;
    font-family: $font-brand;
    font-weight: $font-weight-normal;
    height: auto;
    letter-spacing: 0.05rem;
    margin-left: $space-three-quarters;
    margin-top: 1px;
    padding: $space-third $space-half $space-third $space-and-three-quarters;
    position: relative;
    right: auto;
    text-transform: uppercase;
    top: auto;
    transition: background-color 0.3s;
    vertical-align: middle;
    width: auto;
  }

  @include media('≥l') {
    margin-left: $space-and-sixth;
    padding: $space-half $space-two-thirds $space-half ($space-double + $space-sixth);
  }

  @include media('≥max') {
    margin-left: $space-and-half;
  }

  .icon--hamburger {
    height: 11px;
    margin-top: -6px;
    position: absolute;
    right: 0;
    top: 50%;
    width: $space-five-sixths;

    @include media('≥m') {
      left: $space-half;
      right: auto;
    }

    @include media('≥l') {
      height: 13px;
      left: $space-two-thirds;
      width: $space;
    }

    &::before,
    &::after,
    .icon__line {
      background-color: $white;
      border-radius: 2.5px;
      content: '';
      display: inline-block;
      height: 1px;
      left: 0;
      position: absolute;
      transition: 0.3s;
      width: $space-five-sixths;

      @include media('≥l') {
        width: $space;
      }
    }

    &::before {
      top: 0;
    }

    .icon__line {
      top: 5px;
      transition: 0.15s;

      @include media('≥l') {
        top: 6px;
      }
    }

    &::after {
      bottom: 0;
    }
  }

  &.active {
    .icon--hamburger {
      &::before,
      &::after {
        top: 50%;
        transform-origin: center;
      }

      &::before {
        transform: rotate(45deg);
      }

      .icon__line {
        left: -10px;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }

  &:hover,
  &:focus {
    @include media('≥m') {
      background-color: rgba($black, 0.25);
    }
  }
}





//
// Site header toggle
//
.site-header__toggle-text {
  display: none;

  @include media('≥m') {
    display: inline-block;
    padding-top: 1px;
  }
}
