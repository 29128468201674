// -----------------------------------------------------------------------------
// This file contains styles that are specific to the event page.
// -----------------------------------------------------------------------------

.event {
  .button {
    margin-bottom: $space-half;
    margin-right: $space-half;
  }
}



// Heading
.event__heading {
  margin-bottom: $space-half;
  margin-right: $space-quarter;
  margin-top: 0;
}





// Meta (date and categories)
.list--event-meta {
  margin-bottom: $space-three-quarters;



  // Item
  .list__item {
    display: inline-block;
    margin-bottom: $space-quarter;
    margin-right: $space-half;
    position: relative;
  }



  // Icon
  .icon {
    display: inline-block;
    margin-right: $space-quarter;
    vertical-align: middle;
  }

  .icon__svg {
    fill: $text-color;
  }
}





// Image
.event__image-wrapper {
  margin-bottom: $space;
}

.event__image {
  display: block;
}





// Contact
.list--event-contact {
  .list__content {
    margin-bottom: $space-half;

    @include media('≥s') {
      border-bottom: 1px solid $fountain-grey;
      padding-bottom: $space-half;
      padding-top: $space-third;
    }

    @include media('≥s') {
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }

    &:first-child {
      @include media('≥s') {
        border-top: 1px solid $fountain-grey;
      }
    }
  }

  .list__item--term {
    @include type-setting(2);

    color: $african-violet;
    font-family: $font-brand;
    font-weight: $font-weight-medium;

    @include media('≥s') {
      width: percentage(math.div(4, 12));
    }
  }

  .list__item--definition {
    margin-left: 0;

    @include media('≥s') {
      width: percentage(math.div(8, 12));
    }
  }
}
