// -----------------------------------------------------------------------------
// This file contains all styles related to the download component.
// -----------------------------------------------------------------------------

.download {
  display: inline-block;
  margin-bottom: $space-half;
  margin-right: $space;
  margin-top: $space-half;
  padding-left: $space-double + $space-third;
  position: relative;
  transition: background-position 0.3s;

  .icon {
    height: $space-and-two-thirds;
    left: 0;
    position: absolute;
    top: $space-twelth;
    transition: top 0.3s;
    width: $space-and-two-thirds;

    @include media('≥l') {
      height: $space-and-third;
      width: $space-and-third;
    }
  }

  .icon__svg {
    fill: $asphalt;
  }

  &:hover,
  &:focus {
    .download__heading {
      color: $african-violet;
    }
  }
}

.download__heading {
  @extend %sub-subheading;

  color: $mulberry;
  display: block;
  font-family: $font-brand;
  font-weight: $font-weight-normal;
  margin: 0;
  transition: color 0.3s;
}

.download__text {
  color: $text-color-light;
  display: block;
}
