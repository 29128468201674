// -----------------------------------------------------------------------------
// This file contains all styles related to the logo strip component.
// -----------------------------------------------------------------------------

//
// Main logo strip styles
//
.logo-strip {
  text-align: center;
}





//
// Logo layout styles
//
.logo-strip__logo {
  display: inline-block;
  padding: 0 $gutter-half $gutter-half;
  vertical-align: middle;
  width: percentage(math.div(6, 12));

  @include media('≥xs') {
    width: percentage(math.div(4, 12));
  }

  @include media('≥s') {
    width: percentage(math.div(3, 12));
  }

  @include media('≥m') {
    width: percentage(math.div(2, 12));
  }

  @include media('≥l') {
    width: percentage(math.div(1.5, 12));
  }
}





//
// Logo link styles
//
.logo-strip__link {
  display: block;
}





//
// Logo image styles
//
.logo-strip__image {
  width: 100%;
}
