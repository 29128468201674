// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

//
// Set up a decent box model on the root element
//
html {
  box-sizing: border-box;

  &.fixed {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}




//
// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
//
*,
*::before,
*::after {
  box-sizing: inherit;
}





//
// Basic styles for links
//
a {
  color: $mulberry;
  text-decoration: none;
  transition: 0.3s;

  &:hover,
  &:focus {
    color: $african-violet;
    text-decoration: none;
  }

  .lead-paragraph &,
  .editor &,
  .list--contact & {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}





//
// Basic styles for images
//
img {
  font-style: italic;
  max-width: 100%;
}
