// -----------------------------------------------------------------------------
// This file contains all styles related to the step by step component
// -----------------------------------------------------------------------------

// Content type container
.content-type--step {
  // Icon
  .icon--chevron-down {
    display: block;
    height: $space-and-half;
    margin-bottom: $space-three-quarters;
    margin-top: -$space-three-quarters;
    width: 100%;

    @include media('≥l') {
      height: $space-double + $space-half;
      margin-bottom: $space;
      margin-top: -$space;
    }

    .icon__svg {
      fill: $asphalt;
    }
  }



  // Media link
  .media-link {
    display: block;
    margin-bottom: $space;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    @include media('≥s') {
      float: right;
      margin-bottom: 0;
      margin-top: $space-third;
      padding-left: $gutter;
      padding-right: 0;
      width: percentage(math.div(4, 12));
    }
  }



  // If step is the last item on page or if chevron is hidden manually
  &:last-child,
  &.chevron-hidden {
    margin-bottom: $space-double;

    // Hide icon
    .icon--chevron-down {
      display: none;
    }
  }
}





// Step
.step {
  background-color: $light-grey;
  border-radius: $space-half;
  padding: $space-three-quarters $space-five-sixths $space-and-half;

  @include media('≥l') {
    margin-left: -$space-and-two-thirds;
    margin-right: -$space-and-two-thirds;
    padding: $space-and-quarter $space-and-two-thirds $space-and-half;
  }



  // Button
  .button {
    clear: both;
    margin-bottom: $space-half;
    margin-right: $space-quarter;
  }
}



// Container
.step__container {
  @include clearfix;
}




// Step image
.step__image {
  display: block;
  margin-bottom: $space;
  width: 100%;

  @include media('≥s') {
    float: right;
    margin-bottom: $space-half;
    margin-top: $space-third;
    padding-left: $gutter;
    width: percentage(math.div(4, 12));
  }
}





// Step content
.step__content {
  .has-image & {
    @include media('≥s') {
      float: left;
      width: percentage(math.div(8, 12));
    }
  }

  .has-video & {
    @include media('≥s') {
      float: left;
      padding-right: $gutter;
      width: percentage(math.div(8, 12));
    }
  }
}





// Step heading
.step__heading {
  @extend %heading;

  color: $asphalt;
  margin-top: 0;
}
