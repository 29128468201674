// -----------------------------------------------------------------------------
// This file contains all styles related to the tabs component
// -----------------------------------------------------------------------------

// Essential styling
.js-tabcontent {
  &[aria-hidden="true"] {
    display: none;
  }
}





// Tabs
.tabs {
  margin-bottom: $space-and-half;
  margin-top: $space-double;
}





// Tabs list
.tabs__list {
  align-items: stretch;
  background-color: $light-grey;
  display: flex;
  flex-wrap: wrap;
  font-family: $font-brand;
  margin-bottom: $space-and-sixth;
  width: 100%;

  @include media('≥m') {
    @include type-setting(1);
  }
}




// Tab item
.tabs__item {
  align-items: stretch;
  display: flex;
  flex-grow: 1;
  text-align: center;

  &:last-child {
    border-right: 0;
  }
}





// Tab link
.tabs__link {
  align-items: center;
  border-bottom: 1px solid $fountain-grey;
  color: $text-color;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: $space-quarter;

  @include media('≥m') {
    padding-bottom: $space-half;
    padding-top: $space-half;
  }



  // Active tab
  &[aria-selected="true"] {
    &,
    &:hover,
    &:focus {
      background-color: $african-violet;
      border-bottom-color: $african-violet;
      color: $white;
      cursor: default;
      outline: 0;
    }
  }



  // Interaction
  &:hover,
  &:focus {
    background-color: $mulberry;
    border-color: $mulberry;
    color: $white;
  }
}





// Tab content
.tabs__content {
  @extend %module;

  padding-bottom: $space;



  // Visually hide the heading
  .invisible {
    @extend %visually-hidden;
  }
}
