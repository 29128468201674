// -----------------------------------------------------------------------------
// This file contains all styles related to the social component
// -----------------------------------------------------------------------------

.social {
  margin-bottom: $space;
  margin-top: $space;
}





// Link
.social__link {
  display: inline-block;
  margin-bottom: $space-two-thirds;
  margin-right: $space-half;
  transition: transform 0.3s;

  &:hover,
  &:focus {
    transform: scale(1.2);

    .icon__svg {
      fill: $african-violet-light;
    }
  }



  // Icon
  .icon {
    height: $space-and-half;
    width: $space-and-half;
  }

  .icon__svg {
    fill: $african-violet;
    transition: fill 0.3s;
  }
}





// Centred
.content-type--social {
  &.content-type--centre {
    text-align: center;



    // Link
    .social__link {
      margin-left: $space-quarter;
      margin-right: $space-quarter;
    }
  }
}
