// -----------------------------------------------------------------------------
// This file contains all styles related to the links list component.
// -----------------------------------------------------------------------------

.links-list {
  margin-bottom: $space;

  &.width {
    @include media('≥s') {
      display: inline-block;
      padding-right: $gutter;
      vertical-align: top;
      width: percentage(math.div(6, 12));
    }
  }

  &.width--25 {
    @include media('≥l') {
      width: percentage(math.div(3, 12));
    }
  }

  &.width--33 {
    @include media('≥l') {
      width: percentage(math.div(4, 12));
    }
  }

  &.width--50 {
    @include media('≥l') {
      width: percentage(math.div(6, 12));
    }
  }

  &.width--66 {
    @include media('≥l') {
      width: percentage(math.div(8, 12));
    }
  }

  &.width--75 {
    @include media('≥l') {
      width: percentage(math.div(9, 12));
    }
  }

  &.width--100 {
    @include media('≥l') {
      padding-right: 0;
      width: percentage(math.div(12, 12));
    }
  }



  // Lead paragraph
  .lead-paragraph {
    @include type-setting(0);

    margin-bottom: $space-two-thirds;
  }
}





// Heading
.links-list__heading {
  @extend %subheading;

  color: $african-violet;
  margin-bottom: $space-third;
  margin-top: 0;
}
